<template>
    <div>
        <div class="fullscreenLoading" v-show="fullscreenLoading">
            <div :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }" class="wrap_full">
            <img  :src="require('@/assets/card/loading.gif')"  class="img_card" />
        </div>
    </div>
    <div class="bg_wrap" v-if="state != 'going' && state == 'unstart' || state != 'going' && state == 'finish'">
            <div v-if="state != 'going' && state == 'unstart'" class="activity-start"    
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }" :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }">
                <img  :src="require('@/assets/card/start_bg.png')" class="img_card" />
            </div>
           
                <div v-if="state != 'going' && state == 'finish'" class="activity-finish" 
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }" :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }">
                <img  :src="require('@/assets/card/end_bg.png')" class="img_card" />
            </div>
        </div>
        <!-- <div class="bg_wrap" v-if="state != 'going' && state == 'unstart' || state != 'going' && state == 'finish'">
            <div v-if="state != 'going' && state == 'unstart'" class="activity-start"
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }"></div>
            <div v-if="state != 'going' && state == 'finish'" class="activity-finish"
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }"></div>
        </div> -->
        <div class="activity" v-if="acceptData?.activity && state == 'going'" v-show="!fullscreenLoading" ref="activity">
            <img :src="acceptData?.adv_bottom_img"
                style="position: absolute;top:0;bottom:0;left:0;right:0;width: 100%;height: 100%;" v-if="this.acceptData.screen_type != 0" />
                <div v-else style="height: 100vh;
    position: relative;
    background-repeat: no-repeat!important;
    background-position: top!important;" :style="{ 'background-color':  mode == 'v' ? acceptData?.activity?.bottom_color:'', 'background-image': mode == 'h' ? 'url(' + acceptData?.activity.bg_img + ')' : '' }">
                </div>
               
            <div class="activity-wrap" :style="{ 'width': wrapWidth, 'height': wrapHeight}" 
            :class = "{'mCenter':mode == 'h'&&screen_part_type == 2 ,'dingwei':mode == 'v','resopnse':this.acceptData.screen_type==0 }" ref="activityWrap"
            >
            <div :class="{'liangfen': acceptData.screen_type!=0 &&screen_part_type == 3 && fenshu == 'v'}" :style="{ 'top':acceptData.screen_type!=0 &&screen_part_type == 3 && fenshu == 'v'?mt:''}" >
                <div class="activityResponse" v-show="state == 'going'" ref="container"
                style="background-size: cover;"
                    :class="{ 'activity-container':this.acceptData.screen_type == 0,'horizontal': mode == 'h', 'vertical': mode == 'v', 'no_adv': !acceptData.activity.left_banner_img }" :style="{ 'background-color':  this.acceptData.screen_type!=0 ?acceptData?.activity?.bottom_color:'', 'background-image': this.acceptData.screen_type!=0 ? 'url(' + acceptData?.activity.bg_img + ')' : '' }">
                    <div class="activity-bg"  :style="{ 'background-image': 'url(' + (mode == 'v' ? acceptData?.activity.bg_h_img : '') + ')' }">
                        <div class="activity-counter" ref="counter"
                            v-if="JSON.stringify(countObj) != '[]' && (acceptData.activity && (acceptData.activity.online_show == 1 || acceptData.activity.join_show == 1) || acceptData.reside_show == 1 || acceptData.flow_show == 1)">
                            <ul>
                                <li  :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }"
                                    v-if="acceptData.current_select.current == 'activity' && acceptData.activity.online_show == 1">
                                    <strong class="count-font green">{{ countObj.online || 0 }}</strong>
                                    <span>实时在线人数</span>
                                </li>
                                <li  :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }"
                                    v-if="acceptData.current_select.current == 'activity' && acceptData.activity.join_show == 1">
                                    <strong class="count-font orange">{{ countObj.join || 0 }}</strong>
                                    <span>累计参与人数</span>
                                </li>
                                <li v-if="acceptData.reside_show == 1"  :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }" >
                                    <strong class="count-font yellow">{{ countObj.flow }}</strong>
                                    <span>驻留人数</span>
                                </li>
                                <li v-if="acceptData.flow_show == 1"  :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'margin-right': -(85*(1 - screenRadio)/2 +10) + 'px','margin-left': -(85*(1 - screenRadio)/2 +10) + 'px' }">
                                    <strong class="count-font purple">{{ countObj.pass }}</strong>
                                    <span>客流人次</span>
                                </li>
                            </ul>
                        </div>
                        <div class="activity-main">
                            <div class="activity-content-side" v-if="mode == 'h'"
                                :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-left': mleft + 'px' }">
                                <div class="activity-module-qrcode" v-if="acceptData && acceptData.activity.qrcode_img"
                                    :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                                    <div class="activity-module-qrcode-item">
                                        <img :src="acceptData.activity.qrcode_path" />
                                    </div>
                                </div>
                                <!-- <div class="activity-module-prize" v-if="acceptData && acceptData.activity.h_best_user_img"
                                    :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''"
                                    :style="{ 'background-image': 'url(' + acceptData.activity.h_best_user_img + ')' }">
                                    <div class="activity-module-player">
                                        <ul>
                                            <li v-for="(item, index) of playerLst" :class="{ 'win': item.win_type > 0 }"
                                                :key="index">
                                                <div class="flex_wrap">
                                                    <img class="top_icon" v-if="index == 0"
                                                        src="../../../assets/card/top1@2x.png" />
                                                    <img class="top_icon" v-if="index == 1"
                                                        src="../../../assets/card/top2@2x.png" />
                                                    <img class="top_icon" v-if="index == 2"
                                                        src="../../../assets/card/top3@2x.png" />
                                                    <img class="top_icon" v-if="index == 3"
                                                        src="../../../assets/card/top4@2x.png" />
                                                    <img class="top_icon" v-if="index == 4"
                                                        src="../../../assets/card/top5@2x.png" />
                                                    <div class="player_wrap">
                                                        <div class="player_ava"> <img :src="item.avatar"
                                                                v-if="item.avatar" />
                                                            <img src="@/assets/card/feault.png" v-else />
                                                        </div>
                                                        <p class="nichengs">{{ item.nickname }}</p>
                                                    </div>
                                                </div>
                                                <p class="defen" v-if="item.total_points >= 0">得分{{ item.total_points }}</p>
                                                <p class="defen" v-else>{{ item.total_points }}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->

                                <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img"
                                    :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                                    <img class="activity-module-adv-img activity-module-adv-img-h"
                                        :src="acceptData.activity.left_banner_img" />
                                </div>
                            </div>
                            <div class="activity-content-middle"
                                :style="{ 'margin-right': mleft + 'px', 'margin-left': mleft + 'px' }">
                                <div class="activity-title">
                                    <img :src="acceptData.activity.title_img"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                                </div>
                                <div class="activity-content-item" ref="content">
                                    <ul class="activity-user-box">
                                        <li :class="{ 'win': item.is_prize === 2 }" v-for="(item, index) in currentList"
                                            :key="index"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                            <img class="user-win" src="@/assets/littleGameOne/prize@2x.png"
                                                v-if="item.is_prize === 2"  :class="{ 'user-head2': this.acceptData.screen_type==0 }"/>
                                            <img class="user-head" 
                                                :src="item.avatar || require('@/assets/moonBetting/user.png')" />
                                            <p class="user-name"> {{item.nickname }}</p>
                                            <span class="user-time">{{ item.use_time }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="activity-content-side" v-if="mode == 'h'"
                                :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': mleft + 'px' }">
                                <div class="activity-module-prize" v-if="mode == 'h' && acceptData"
                                    :style="{ 'background-image': 'url(' + acceptData.activity.victory_area_img + ')' }">
                                    <div class="activity-module-user">
                                        <div class="activity-module-head" :class="{ 'win': awardList?.[awardIndex] }">
                                            <img :src="awardList?.[awardIndex]?.avatar || require('@/assets/littleGameOne/default@2x.png')"
                                                class="avatar_touxiang" />
                                        </div>
                                        <div class="activity-module-info">
                                            <div v-if="awardList?.[awardIndex]">
                                                <p class="activity-module-info-name">{{ awardList?.[awardIndex]?.nickname }}
                                                </p>
                                                <span class="activity-module-info-desc"> {{
                                                    awardList?.[awardIndex]?.victory_name }}</span>
                                            </div>
                                            <span v-else
                                                style="color: #a43939;font-weight: bold;font-size: 30px;line-height: 135px; opacity: .4;">虚位以待</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                                    <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img" />
                                </div>
                            </div>
                            <div class="activity-content-bottom" v-if="mode == 'v'">
                                <div class="activity-module-qrcode" v-if="acceptData && acceptData.activity.qrcode_img"
                                    :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                                    <div class="activity-module-qrcode-item-v">
                                        <img :src="acceptData.activity.qrcode_path" />
                                    </div>
                                </div>
                                <div class="activity-module-prize activity-module-prize-right"
                                    :style="{ 'background-image': 'url(' + acceptData.activity.victory_area_img + ')' }">
                                    <div class="activity-module-user">
                                        <div class="activity-module-head activity-module-head-v"
                                            :class="{ 'win': awardList?.[awardIndex] }">
                                            <img :src="awardList?.[awardIndex]?.avatar || require('@/assets/littleGameOne/default@2x.png')"
                                                class="avatar_touxiang" />
                                        </div>
                                        <div class="activity-module-info">
                                            <div v-if="awardList?.[awardIndex]">
                                                <p class="activity-module-info-name">{{ awardList?.[awardIndex]?.nickname }}
                                                </p>
                                                <span class="activity-module-info-desc"> {{
                                                    awardList?.[awardIndex]?.victory_name }}</span>
                                            </div>
                                            <span v-else
                                                style="color: #a43939;font-weight: bold;font-size: 30px;line-height: 135px; opacity: .4;">虚位以待</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="activity-adv"
                                v-if="mode == 'v' && acceptData.activity.left_banner_img && acceptData.activity.right_banner_img">
                                <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                                    <img class="activity-module-adv-img activity-module-adv-imgs"
                                        :src="acceptData.activity.left_banner_img" />
                                </div>
                                <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                                    <img class="activity-module-adv-img  activity-module-adv-imgs"
                                        :src="acceptData.activity.right_banner_img" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="index-index-drop" v-show="dropStatus">
                        <div v-for="(item, index) of userDetails" :key="index">
                            <transition enter-active-class="animated bounceIn" leave-active-class="animated bounceOut"
                                :duration="600">
                                <div class="drop-box" v-show="dropStatus">
                                    <img class="box" :src="require('@/assets/littleGameOne/getbox@2x.png')"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                                    <img class="border" :src="require('@/assets/littleGameOne/getBorder@2x.png')"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                                    <img class="head"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }"
                                        :src="item.avatar || require('@/assets/littleGameOne/default@2x.png')" />
                                    <div class="drop-index-detail"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                        <div class="time">
                                            {{ item.use_time }}
                                        </div>
                                        <div class="nickname">
                                            {{ item.nickname }}
                                        </div>
                                        <div class="content">
                                            <span v-if="item.victory_type == 4">【现金】</span>
                                            {{ item.awardShowName }}
                                        </div>
                                        <div class="image">
                                            <img :src="item.awardImg" />
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="mask" ref="mask" :style="{ 'opacity': 0 }">
                        <div class="pop-winner" :class="isscaleSuo == 8 ? 'scaleSuo' : (isscaleSuo == 9 ? 'scaleSuo9' : '')"
                            v-if="newList.length <= 3 || newList.length == 4 && mode == 'h'">
                            <div class="pop-winner_wrap">
                                <div v-for="(item, index) of newList" :key="index" :class="['pop-' + item.index, 'show']">
                                    <transition enter-active-class="animated bounceIn"
                                        leave-active-class="animated bounceOut" :duration="600">
                                        <div class="winner-item">
                                            <div class="winner-body">
                                                <div class="winner-name">{{ item.nickname }}</div>
                                                <div class="winner-prz">
                                                    {{ item.victory_name }}
                                                </div>
                                                <div class="winner-prz-info">
                                                    <img class="winner-prz-info-img" :src="item.victory_img" v-if="item.victory_img"/>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="pop-winner pop-winners" v-if="newList.length == 4 && mode == 'v'">
                            <div class="pop-winner_wrap pop-winner_wraps">
                                <div v-for="(item, index) of newList" :key="index" class="winner-items"
                                    :class="['pop-' + item.index, 'show']">
                                    <transition enter-active-class="animated bounceIn"
                                        leave-active-class="animated bounceOut" :duration="600">
                                        <div class="winner-item">
                                            <div class="winner-body">
                                                <div class="winner-name">{{ item.nickname }}</div>
                                                <div class="winner-prz">
                                                    {{ item.victory_name }}
                                                </div>
                                                <div class="winner-prz-info">
                                                    <img class="winner-prz-info-img" :src="item.victory_img"  v-if="item.victory_img"/>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mask_yindao heng" v-if="mode == 'h'" ref="mask_yindao" :style="{ 'opacity': 0 }">
                        <div :class="['pop-' + 'show']">
                            <div class="mask_yindao_wrap">
                                <div class="mask_yindao_content" :class="{'mt240':screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length>=3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >=3}">
                                    <img class="sao" src="../../../assets/card/sao.png"
                                        :style="{ 'transform':mode == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadio + ')') }" />
                                    <div class="sao_wrap sao_wrap_img" :style="{ 'transform':mode == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadio + ')') }">
                                        <img class="code" :src="acceptData.activity.qrcode_path" />
                                    </div>
                                    <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                        :style="{ 'transform':mode == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadio + ')') }" />
                                </div>
                                <div class="layerLst_wrap" 
                                :class="{ 'weizhi': screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length>=3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >=3}"
                                    :style="{ 'transform': prizeCountListT.length>=3? 'translateX(' + (100 - (100 / screenRadio)) / 2 + '%)' : '', 'left': prizeCountListT.length>=3? (100 - (100 / screenRadio)) / 2 + '%' : '', width: prizeCountListT.length>=3 ? (100 / screenRadio) + '%' : '', 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                    <div v-for="(item, index) in prizeCountListT" :key="index">
                                        <div class="victory_bg">
                                            <img class="victory_img" :src="item.victory_img" v-if="item.victory_img"/>
                                            <div class="victory_name">
                                                <p class="victory_text">{{ item.victory_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mask_yindao ver" v-if="mode == 'v' && this.prizeCountListT.length == 5" ref="mask_yindao"
                        :style="{ 'opacity': 0 }">
                        <div class="mask_yindao_wrap mask_yindao_wrap05">
                            <div class="mask_yindao_content">
                                <img class="sao" src="../../../assets/card/sao.png"
                                    />
                                <div class="sao_wrap">
                                    <img class="code" :src="acceptData.activity.qrcode_path"
                                         />
                                </div>
                                <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                    />
                            </div>
                            <div class="layerLst_wrap layerLst_wrap05">
                                <div v-for="(item, index) in prizeCountListT" :key="index">
                                    <div class="victory_bg">
                                        <img class="victory_img" :src="item.victory_img" v-if="item.victory_img"/>
                                        <div class="victory_name">
                                            <p class="victory_text">{{ item.victory_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length == 4"
                        ref="mask_yindao" :style="{ 'opacity': 0 }">
                        <div class="mask_yindao_wrap mask_yindao_wrap04">
                            <div class="mask_yindao_content">
                                <img class="sao" src="../../../assets/card/sao.png"
                                    />
                                <div class="sao_wrap">
                                    <img class="code" :src="acceptData.activity.qrcode_path"
                                        />
                                </div>
                                <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                    />
                            </div>
                            <div class="layerLst_wrap">
                                <div v-for="(item, index) in prizeCountListT" :key="index">
                                    <div class="victory_bg">
                                        <img class="victory_img" :src="item.victory_img" v-if="item.victory_img" />
                                        <div class="victory_name">
                                            <p class="victory_text">{{ item.victory_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length < 4"
                        ref="mask_yindao" :style="{ 'opacity': 0 }">
                        <div class="mask_yindao_wrap mask_yindao_wrap03">
                            <div class="mask_yindao_content">
                                <img class="sao" src="../../../assets/card/sao.png"
                                     />
                                <div class="sao_wrap">
                                    <img class="code" :src="acceptData.activity.qrcode_path"
                                        />
                                </div>
                                <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                    />
                            </div>
                            <div class="layerLst_wrap">
                                <div v-for="(item, index) in prizeCountListT" :key="index">
                                    <div class="victory_bg">
                                        <img class="victory_img" :src="item.victory_img"  v-if="item.victory_img"/>
                                        <div class="victory_name">
                                            <p class="victory_text">{{ item.victory_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            </div>
        </div>


    </div>
</template>

<script>
let pageCurrent = "index";
let selectedActivity = "";
let newListData = [];
let lastListData = [];
import { TweenMax } from 'gsap'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
import {
    getPrizeNewLst,
    getLatestListCard,
    getTopPrizeUserListCard,
    getPrizeNumListCard,
    getTopPointsUserList,
    getOverView,
    tenUserOffset,
    tenTopList,
} from '@/api/littleGameOne.js'
import { throwStatement } from '@babel/types';
export default {
    name: "moduleCard",
    components: {
        vueSeamlessScroll
    },
    data() {
        return {
            screenRadio: '1',
            awardList: [],
            awardIndex: -1,
            dropTime: 3000,
            userDetail: {},
            dropStatus: false,
            offsetValue: 0,
            offsetValues: 0,
            isscaleSuo: true,
            leftVal: 0,  // 轮播图盒子的偏移值
            flag: true, // 用来节流防止重复点击j
            start: null, // 自动执行下一张定的时器
            imgWidth: 500,  // 在这里填写你需要的图片宽度
            ition: 0.8, // 设置轮播图过度时间
            imgShow: 0,
            uniq: "",
            acceptData: {},
            mode: 'h',
            state: 'going',
            countObj: {
                online: 0,
                join: 0,
                flow: 0,
                pass: 0
            },
            prizeList: [],
            userList: [],
            topUserList: [],
            topUserListV: [],
            currentList: [],
            playerLst: [],
            prizeCountList: [],
            prizeCountListT: [],
            newList: [],
            count: 0,
            countRefresh: 0,
            currentIndex: 0,
            randomArr: [],
            scale: 1,
            oldUserList: [],
            layerLst: [],
            optionSwitch1: {

            },
            wrapWidth: '',
            wrapHeight: '',
            bgx: '',
            reversed_width: '',
            reversed_height: '',
            scaleFen: 1,
            mleft: 0,
            mRight: 0,
            screen_part_type: 2,
            userLeft: 0,
            screenRadios: 1,
            fenshu:'h',
            mt:0,
            userDetails:[]
        }
    },


    mounted() {
        this.screen_type = localStorage.getItem('screen_type')
            let w = 0
            let h = 0
            if (this.screen_type == 9) {
                w = 1920
                h = 560
            } else if (this.screen_type == 8) {
                w = 1920
                h = 360
            } else if (this.screen_type == 7) {
                w = 1920
                h = 640
            } else if (this.screen_type == 6) {
                w = 1920
                h = 1080
            } else if (this.screen_type == 5) {
                w = 1600
                h = 1200
            } else if (this.screen_type == 4) {
                w = 1400
                h = 1400
            } else if (this.screen_type == 3) {
                w = 1000
                h = 1500
            } else if (this.screen_type == 2) {
                w = 1000
                h = 2000
            } else if (this.screen_type == 1) {//自定义
                w = localStorage.getItem('screen_width')
                h = localStorage.getItem('screen_length')
            } else if (this.screen_type == 0) {//默认自适应
                w = 1920
                h = 1080
                this.screen_part_type = 0
               
            }

            //横屏
            if (16 / 9 < w / h) {
                this.fenshu = 'h'
                this.wrapHeight = '100vh'
                this.wrapWidth = 16 / 9 * h + 'px'
                if (this.screen_type == 0) {//默认自适应
                    this.screenRadio = 1
                    this.mleft = 0
                } else {//3分|| 2分屏
                    this.screenRadio = 16 / 9 / (w / h)
                    this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
                }

            } else {
                //竖屏
                this.fenshu = 'v'
                this.wrapWidth = '100%'
                this.screenRadio = 1
                this.screenRadios = 9 / 16 * (w / h)
                console.log(this.screenRadios)
            }
            this.state = localStorage.getItem('state')
        let that = this
        setInterval(() => {
            that.open();
            setTimeout(() => {
                that.close()
            }, 5000)
        }, 10000)

        this.coll_id = this.$route.params.id;
        this.fullscreenLoading = true;
        this.resizeSetting();
        setInterval(() => {
            if (pageCurrent != "index" && this.uniq) {
                this.getUserListOffset(this.uniq);
                this.getUserListOffsetS(this.uniq);
                this.getTenTopList(this.uniq);
            }
        }, 5000);

    },
    methods: {
        getUserListOffset(uniq) {
            tenUserOffset({
                colle_uniq: this.coll_id,
                uniq: uniq,
                pageSize: 10,
                offset: this.offsetValue,
            }).then((res) => {
                if (res.status === 200) {
                    let { userList, offset } = res.data;
                    if (offset !== this.offsetValue) {
                        this.userList = this.userList.concat(userList);
                        this.offsetValue = offset;
                    }
                    let index = this.currentList?.length || 0;
                    let detail = this.userList[index];
                    if (detail) {
                        if (detail.is_prize === 2) {
                          //  this.userDetail = detail;
                           // this.dropStatus = true;
                           // setTimeout(() => (this.dropStatus = false), this.dropTime);
                        }
                        this.currentList.unshift(detail);
                    }
                }
            });
        },
        getUserListOffsetS(uniq) {
            tenUserOffset({
                colle_uniq: this.coll_id,
                uniq: uniq,
                pageSize: 4,
                offset: this.offsetValues,
                type:2,
            }).then((res) => {
                if (res.status === 200) {
                    let { userList, offset } = res.data;
                    if (offset !== this.offsetValues) {
                       // this.userList = this.userList.concat(userList);
                        this.offsetValues = offset;
                    }
                    let index = userList?.length || 0;
                    let detail = userList[0];
                    console.log(index)
                    if (detail) {
                        if (detail.is_prize === 2) {
                            this.userDetails =  res.data.userList;
                            this.dropStatus = true;
                            setTimeout(() => (this.dropStatus = false), this.dropTime);
                        }
                        //this.currentList.unshift(detail);
                    }
                }
            });
        },
        getTenTopList(uniq) {
            tenTopList({
                colle_uniq: this.coll_id,
                uniq: uniq,
                topNumber: 10,
            }).then((res) => {
                if (res.status === 200) {
                    this.awardList = res.data.result || [];
                    console.log(this.awardList)
                    this.getSwiperTimeout(uniq);
                }
            });
        },
        getSwiperTimeout() {
            if (this.awardList.length) {
                if (this.awardIndex > 0) {
                    this.awardIndex--;
                } else {
                    this.awardIndex = this.awardList.length - 1;
                }
            }
        },

        open() {
            TweenMax.to(this.$refs.mask_yindao, 1, {
                opacity: 1,
            });
        },
        close() {
            TweenMax.to(this.$refs.mask_yindao, 1, {
                opacity: 0,
            });
        },
        getParentData(data,screenRadios) {
            if (
                ((pageCurrent && pageCurrent != data.current_select.current) ||
                    (selectedActivity && selectedActivity != data.current_select.selectValue))
            ) {
                if (this.countRefresh == 1) {
                    this.fullscreenLoading = true
                    window.location.reload();
                    return;
                }
                this.countRefresh++
            }
            selectedActivity = data.current_select.selectValue;
            pageCurrent = data.current_select.current;

            this.uniq = data.activity.uniq;
            this.acceptData = { ...data };
            this.coll_id = this.$route.params.id;
            this.screen_part_type = this.acceptData.screen_part_type
            let w = 0
            let h = 0
            if (this.acceptData.screen_type == 9) {
                w = 1920
                h = 560
            } else if (this.acceptData.screen_type == 8) {
                w = 1920
                h = 360
            } else if (this.acceptData.screen_type == 7) {
                w = 1920
                h = 640
            } else if (this.acceptData.screen_type == 6) {
                w = 1920
                h = 1080
            } else if (this.acceptData.screen_type == 5) {
                w = 1600
                h = 1200
            } else if (this.acceptData.screen_type == 4) {
                w = 1400
                h = 1400
            } else if (this.acceptData.screen_type == 3) {
                w = 1000
                h = 1500
            } else if (this.acceptData.screen_type == 2) {
                w = 1000
                h = 2000
            } else if (this.acceptData.screen_type == 1) {//自定义
                w = this.acceptData.screen_width
                h = this.acceptData.screen_length
            } else if (this.acceptData.screen_type == 0) {//默认自适应
                w = 1920
                h = 1080
                this.screen_part_type = 0
               
            }

            //横屏
            if (16 / 9 < w / h) {
                this.fenshu='h'
                this.wrapHeight = '100vh'
                this.wrapWidth = 16 / 9 * h + 'px'
                if (this.acceptData.screen_type == 0) {//默认自适应
                    this.screenRadio = 1
                    this.mleft = 0
                } else {//3分|| 2分屏
                    this.screenRadio = 16 / 9 / (w / h)
                    this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
                }

            } else {
                //竖屏
                this.fenshu='v'
                this.wrapWidth = '100%'
                this.screenRadio = 1
                this.screenRadios = 9 / 16 * (w / h)
                if(this.$refs.activityWrap){
                    this.$refs.activityWrap.style.transform = "scaleY(" + screenRadios + ")";
                }
                
                if(this.acceptData.screen_part_type==3){//2分屏幕
                    const _h = document.body.clientHeight;
                    this.mt = (_h - (_h*this.screenRadios))/this.screenRadios +'px'
                   
                }else if(this.acceptData.screen_part_type==2){//3分屏幕 
                    if(this.$refs.activityWrap){
                       //this.$refs.activityWrap.style.top = h*(1 - this.screenRadios)/2 +'px';
                    }
                    
                }
            }

            this.getPrizeList();
            this.getTopUserLists()
            this.getPrizeListCount();
            this.getPrizeListCountT();
            this.getTopUserList();
            this.getTopPointsUserListwanjia();
            this.getOverViewNum(this.acceptData.activity.uniq);

            //表示活动未开始
            const current = new Date().getTime();
            if (data.activity) {
                if (current < this.acceptData.activity.begin_time * 1000) {
                   // this.state = 'unstart';
                    this.count = 0;
                } else if (current > this.acceptData.activity.end_time * 1000 || this.acceptData.activity?.is_termi == 2) {
                   // this.state = 'finish';
                    this.count = 0;
                } else {
                    selectedActivity = data.current_select.selectValue;
                    this.current = data.current_select.current;
                    this.uniq = data.current_select.selectValue;
                    this.defaultcurrent = data.current_select.current;
                    this.activeDetail = data;
                   // this.state = 'going';
                    this.isscaleSuo = data.activity.resolution
                    if (this.count == 0) {
                        this.$nextTick(() => {
                            this.resizeSetting();
                            window.addEventListener("resize", () => {
                                this.resizeSetting();
                            });
                        })
                    }
                    this.count++;
                }
            } else {
                selectedActivity = data.current_select.selectValue;
                this.current = data.current_select.current;
                this.uniq = data.current_select.selectValue;
                this.defaultcurrent = data.current_select.current;
                this.activeDetail = data;
               // this.state = 'going';
            }
            let that = this
            setInterval(() => {
                that.fullscreenLoading = false;
            }, 600)

        },
        arrayCompare(newArr, lastArr) {
            if (lastArr == undefined || newArr == undefined) {
                return [];
            }
            if (lastArr.length == 0 || newArr.length == 0) {
                return newArr;
            }
            var newArr2 = JSON.parse(JSON.stringify(newArr));//复制出新数组
            for (var i in lastArr) {
                var id = lastArr[i].id;
                for (var j in newArr2) {
                    if (id === newArr2[j].id) {
                        newArr2.splice(j, 1);
                        break;
                    }
                }
            }
            return newArr2;
        },

        //获取最新中奖
        getPrizeList() {
            getPrizeNewLst({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                newListData = res.data;
                var addArr = this.arrayCompare(newListData, lastListData);
                lastListData = newListData;
                if (addArr.length > 0) {
                    this.newList = addArr
                    TweenMax.to(this.$refs.mask, 1, {
                        opacity: 1,
                    });
                    this.getUserPop()
                }
            })
        },

        getUserPop() {
            setTimeout(() => {
                this.newList = []
                if (this.newList.length == 0) {
                    TweenMax.to(this.$refs.mask, 1, {
                        opacity: 0,
                    });
                }
            }, 2000);
        },
        getOverViewNum(uniq) {
            getOverView({
                uniq: uniq,
                colle_uniq: this.coll_id,
            }).then((res) => {
                if (res.status == 200) {
                    this.countObj = res.data;
                }

            })
        },

        //获取前十中奖用户
        getTopUserList() {
            getTopPrizeUserListCard({ colle_uniq: this.coll_id, uniq: this.uniq, limit: 7 }).then((res) => {
                this.topUserList = res.data;
                const length = 7 - res.data.length;
                if (res.data.length < 7) {
                    for (let i = 0; i < length; i++) {
                        this.topUserList.push({
                            act_id: 0,
                            avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
                            nickname: "- -",
                            victory_name: "- -",
                            victory_level_name: "- -",
                            win_name: "- -",
                            win_type: 0,
                        });
                    }

                }


            })
        },

        //获取前十中奖用户
        getTopUserLists() {
            getTopPrizeUserListCard({ colle_uniq: this.coll_id, uniq: this.uniq, limit: 10 }).then((res) => {
                this.topUserListV = res.data;
                const lengthV = 10 - res.data.length;
                if (res.data.length < 10) {
                    for (let i = 0; i < lengthV; i++) {
                        this.topUserListV.push({
                            act_id: 0,
                            avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
                            nickname: "- -",
                            victory_name: "- -",
                            victory_level_name: "- -",
                            win_name: "- -",
                            win_type: 0,
                        });
                    }
                }
            })
        },
        //最新玩家
        getTopPointsUserListwanjia() {
            getTopPointsUserList({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                this.playerLst = res.data;
                const length = 5 - res.data.length;
                if (res.data.length < 5) {
                    for (let i = 0; i < length; i++) {
                        this.playerLst.push({
                            act_id: 0,
                            avatar: "",
                            nickname: "还未上榜",
                            total_points: "- -",
                            win_type: 0,
                        });
                    }
                }
            })
        },
        //剩余奖品数量列表
        getPrizeListCount() {
            getPrizeNumListCard({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                for (let i in res.data) {
                    if (res.data[i].victory_level == 1) {
                        res.data[i].victory_level_name = '一等奖'
                    }
                    if (res.data[i].victory_level == 2) {
                        res.data[i].victory_level_name = '二等奖'
                    }
                    if (res.data[i].victory_level == 3) {
                        res.data[i].victory_level_name = '三等奖'
                    }
                    if (res.data[i].victory_level == 4) {
                        res.data[i].victory_level_name = '四等奖'
                    }
                    if (res.data[i].victory_level == 5) {
                        res.data[i].victory_level_name = '五等奖'
                    }
                    if (res.data[i].victory_level == 6) {
                        res.data[i].victory_level_name = '六等奖'
                    }
                    if (res.data[i].victory_level == 7) {
                        res.data[i].victory_level_name = '七等奖'
                    }
                    if (res.data[i].victory_level == 8) {
                        res.data[i].victory_level_name = '八等奖'
                    }
                    if (res.data[i].victory_level == 9) {
                        res.data[i].victory_level_name = '九等奖'
                    }
                    if (res.data[i].victory_level == 10) {
                        res.data[i].victory_level_name = '十等奖'
                    }

                }
                this.prizeCountList = res.data

                this.optionSwitch1 = {
                    direction: 2,
                    autoPlay: true,
                    waitTime: 5000,

                }

            })
        },


        getPrizeListCountT() {
            getPrizeNumListCard({ colle_uniq: this.coll_id, uniq: this.uniq, limit: 5 }).then((res) => {
                for (let i in res.data) {
                    if (res.data[i].victory_level == 1) {
                        res.data[i].victory_level_name = '一等奖'
                    }
                    if (res.data[i].victory_level == 2) {
                        res.data[i].victory_level_name = '二等奖'
                    }
                    if (res.data[i].victory_level == 3) {
                        res.data[i].victory_level_name = '三等奖'
                    }
                    if (res.data[i].victory_level == 4) {
                        res.data[i].victory_level_name = '四等奖'
                    }
                    if (res.data[i].victory_level == 5) {
                        res.data[i].victory_level_name = '五等奖'
                    }
                    if (res.data[i].victory_level == 6) {
                        res.data[i].victory_level_name = '六等奖'
                    }
                    if (res.data[i].victory_level == 7) {
                        res.data[i].victory_level_name = '七等奖'
                    }
                    if (res.data[i].victory_level == 8) {
                        res.data[i].victory_level_name = '八等奖'
                    }
                    if (res.data[i].victory_level == 9) {
                        res.data[i].victory_level_name = '九等奖'
                    }
                    if (res.data[i].victory_level == 10) {
                        res.data[i].victory_level_name = '十等奖'
                    }

                }
                this.prizeCountListT = res.data


            })
        },





        getRandNum(minNum, maxNum, decimalNum) {
            let max = 0, min = 0;
            minNum <= maxNum ? (min = minNum, max = maxNum) : (min = maxNum, max = minNum);
            switch (arguments.length) {
                case 1:
                    return Math.floor(Math.random() * (max + 1));
                case 2:
                    return Math.floor(Math.random() * (max - min + 1) + min);
                case 3:
                    return (Math.random() * (max - min) + min).toFixed(decimalNum);
                default:
                    return Math.random();
            }
        },
        getUniqueRand(min, max, num) {
            const randArr = [];
            for (let i = 0; i < num; i++) {
                const rand = this.getRandNum(min, max);
                if (randArr.indexOf(rand) == -1) {
                    randArr.push(rand);
                } else {
                    i--;
                }
            }
            return randArr
        },
        resizeSetting() {
            const _w = document.body.clientWidth;
            const _h = document.body.clientHeight;
           this.mode = _w >= _h ? "h" : "v";
           if(this.acceptData.screen_type!=10){
            this.mode = _w >= _h ? "h" : "v";
           }else{
            this.mode = _w >= _h ? "v" : "v";
           }
           
            if (this.mode == 'h') {
                const rate = _w > 1440 ? 1920 / 1080 : 1440 / 1080;
                this.isOverTop = rate < _w / _h;
                if (this.$refs.container) {
                    const fixWidth = _w > 1440 ? 1920 : 1440;
                    if (this.isOverTop) {
                        this.$refs.container.style.transform = "scale(" + _h / 1080 + ")";
                        this.scaleFen = _h / 1080
                        this.$refs.container.style.marginTop = -((1080 - _h) / 2 - 1) + "px";
                        this.$refs.container.style.marginLeft = -(fixWidth - _w) / 2 + "px";
                    } else {
                        const currH = _h / (_w / fixWidth);

                        const _style = this.$refs.container.getAttribute("style");
                        const filter_style = _style ? _style.split(";").filter((item) => {
                            return !(item.indexOf("min-width") > -1)
                        }) : "";
                        const _new_style = filter_style.length > 0 ? filter_style.join(";") : "";
                        this.$refs.container.style = _new_style;
                        this.$refs.container.style.transform = "scale(" + _w / fixWidth + ")";
                        this.scaleFen = _w / fixWidth
                        this.$refs.container.style.marginTop = ((_h - currH) / 2) + "px";
                        this.$refs.container.style.marginLeft = -((_w / (_w / fixWidth)) - _w) / 2 + "px";
                        this.$refs.container.style.minHeight = currH + "px";
                        this.$refs.container.style.maxHeight = currH + "px";
                        if (this.$refs.content) {
                            this.$refs.content.style.height = currH - 484 + 200 + "px";
                            console.log(this.$refs.content.style.height)

                        }
                        this.scale = _w / fixWidth;
                    }
                }
            } else {
                if (this.$refs.container) {
                    const currH = (_h / (_w / 1380));
                    const currW = (_w / (_w / 1380));
                    this.$refs.container.style.transform = "scale(" + _w / 1380 + ")";
                    this.scaleFen = _w / 1380
                    this.$refs.container.style.marginTop = -(currH - _h) / 2 + "px";
                    this.$refs.container.style.marginLeft = -(currW - _w) / 2 + "px";
                    this.$refs.container.style.minHeight = currH + "px";
                    this.$refs.container.style.maxHeight = currH + "px";
                    if (this.$refs.content) {
                        if (this.acceptData.activity.left_banner_img) {

                            this.$refs.content.style.height = currH - 1100 + 300 + "px";
                        } else {
                            this.$refs.content.style.height = currH - 920 + 300 + "px";
                        }

                    }
                    this.scale = _w / 1380;
                }
            }
            console.log(this.scaleFen + 'scaleFen')
        }
    }

}
</script>
<style  scoped lang="less" >
@import url('./style.less');
.weizhi{
    position: absolute;
}
.mt240{
    margin-top: -240px;
}
.none {
    display: none
}

.activity-content-middle {
    width: 100%;
    height: 100%;
    flex: auto;
    overflow: hidden;
    position: relative;
    padding: 0 30px;
}



.seamless-warp3 {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    height: 200px;
}


//适配屏幕1920*360
.scaleSuo {
    transform: scale(0.3, 0.9) !important;
}

.scaleyangshi {
    width: calc(100%/20);
}

.scaleactivityMiddle {
    width: 100%;
    height: 100%;
    flex: auto;
    overflow: hidden;
    position: relative;
    padding: 0 !important;
    margin: 0 -60px;
}

.scaleboxli {
    width: 150px !important;

    &.win::after {
        width: 100px !important;
        height: 43px !important;
        margin-left: -47px !important;
    }
}

.scaleicon {
    left: -9px !important;
}

.scaleusername {
    margin-left: 25px !important;
}

.scalelevel {
    margin-left: 9px !important;
}

.scaleSuo {
    transform: scale(0.3, 0.9);
}

//
.scaleSuo9 {
    transform: scale(0.3, 0.8);
}

.mt90 {
    margin-top: -90px !important;
}

.scaleSuo11 {
    transform: scale(0.5, 0.9);
}

.sao_wrap_img .code {
    width: 560px;
    height: 560px;
}

.mCenter {
    margin: 0 auto;
}

.pd20 {
    padding: 0 40px;
}
.dingwei{
    position: absolute;
}
.resopnse{
    position: absolute;
 top: 0
}

</style>
