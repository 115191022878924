<template>
  <div>
    <div class="fullscreenLoading" v-show="fullscreenLoading">
      <div :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }" class="wrap_full">
            <img  :src="require('@/assets/questions/loading.gif')"  class="img_card" />
          </div>
    </div>
    <div class="bg_wrap" v-if="state != 'going' && state == 'unstart' || state != 'going' && state == 'finish'">
            <div v-if="state != 'going' && state == 'unstart'" class="activity-start"    
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }" :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }">
                <img  :src="require('@/assets/redPackage/start.png')" class="img_card" />
            </div>
           
                <div v-if="state != 'going' && state == 'finish'" class="activity-finish" 
                :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }" :style="{ 'transform':fenshu == 'h'? ('scaleX(' + screenRadio + ')'):('scaleY(' + screenRadios + ')') }">
                <img  :src="require('@/assets/redPackage/end.png')" class="img_card" />
            </div>
        </div>
    <div v-show="acceptData.screen_type != 0">

      <div v-show="!fullscreenLoading" class="activity" ref="activity">
        <img :src="acceptData?.adv_bottom_img"
          style="position: absolute;top:0;bottom:0;left:0;right:0;width: 100%;height: 100%;"
          v-if="acceptData.screen_type != 0" />
        <div v-else style="height: 100vh;
              position: relative;
              background-repeat: no-repeat!important;
              background-position: top!important;
              background-size: 100% auto;"
          :style="{ 'background-color': acceptData?.activity?.bottom_color, 'background-image': mode == 'h' ? 'url(' + acceptData?.activity.bg_img + ')' : 'url(' + acceptData?.activity.bg_h_img + ')' }">
        </div>

        <!-- <div v-show="state != 'going' && acceptData" class="activity-start">
          <div class="activity-unstart">
            <div class="activity-begin">
              <a class="activity-btn" :class="{ 'finish': state == 'finish' }" href="javascript:;"></a>
            </div>
          </div>
        </div> -->
        
        <div class="activity-wrap" :style="{ 'width': wrapWidth, 'height': wrapHeight }"
          :class="{ 'mCenter': mode == 'h' && screen_part_type == 2, 'dingwei': mode == 'v', 'resopnse': this.acceptData.screen_type == 0 }"
          ref="activityWrap">
          <div :class="{ 'liangfen': acceptData.screen_type != 0 && screen_part_type == 3 && fenshu == 'v' }"
            :style="{ 'top': acceptData.screen_type != 0 && screen_part_type == 3 && fenshu == 'v' ? mt : '' }">
            <div v-show="state == 'going'" class="activityResponse"
              :class="{ 'activity-container': this.acceptData.screen_type == 0, 'horizontal': mode == 'h', 'vertical pd40': mode == 'v', 'no_adv': !acceptData.activity.left_banner_img }"
              :style="{ 'background-color': this.acceptData.screen_type != 0 ? acceptData?.activity?.bottom_color : '', 'background-image': this.acceptData.screen_type != 0 ? 'url(' + acceptData?.activity.bg_img + ')' : '' }"
              ref="container" v-if="this.acceptData.screen_type != 0" style="margin-left:0;">
              <div class="activity-counter" :style="{ 'marginTop': mode == 'v' && acceptData.screen_type == 10 ? mConter : '' }"
                v-if="JSON.stringify(countObj) != '[]' && (acceptData.activity && (acceptData.activity.online_show == 1 || acceptData.activity.join_show == 1) || acceptData.reside_show == 1 || acceptData.flow_show == 1)">
                <ul>
                  <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.online_show == 1"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }">
                    <strong class="count-font green">{{ countObj.online || 0 }}</strong>
                    <span>实时在线人数</span>
                  </li>
                  <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.join_show == 1"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }">
                    <strong class="count-font orange">{{ countObj.join || 0 }}</strong>
                    <span>累计参与人数</span>
                  </li>
                  <li v-if="acceptData.reside_show == 1"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }">
                    <strong class="count-font yellow">{{ countObj.flow }}</strong>
                    <span>驻留人数</span>
                  </li>
                  <li v-if="acceptData.flow_show == 1"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }">
                    <strong class="count-font purple">{{ countObj.pass }}</strong>
                    <span>客流人次</span>
                  </li>
                </ul>
              </div>

              <div class="activity-main">
                <div class="activity-wrapper" ref="actWrap" :class="mode == 'h' ? 'h100' : ''">
                  <div class="activity-title">
                    <img :src="acceptData.activity.title_img"
                      :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'marginTop': mode == 'v' && acceptData.screen_type == 10 ? mTitile : '' }" />
                  </div>

                  <div class="activity-redpackage" ref="redNum"
                    :style="{ 'transform': mode == 'h' ? 'translateX(-50%)' + ('scaleX(' + screenRadio + ')') : 'translateX(-50%)' + ('scaleY(' + screenRadio + ')'), 'marginTop': mode == 'v' && acceptData.screen_type == 10 ? mRed : '' }">
                    <div>
                      还剩 <span class="yellow1">{{ redNum }}</span> 个红包
                    </div>
                  </div>
                  <div class="activity-content-side" v-if="mode == 'h'"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-left': (mleft + 10) + 'px' }">
                    <div class="activity-module-left-bg" v-if="acceptData && acceptData.activity.qrcode_img"
                      :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                      <div class="activity-module-qrcode"
                        :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                        <div class="activity-module-qrcode-item">
                          <img :src="acceptData.activity.qrcode_path" />
                        </div>
                      </div>
                      <div class="activity-module-prize" v-if="acceptData"
                        :style="{ 'background-image': 'url(' + acceptData.activity.prize_img + ')' }">
                        <div class="activity-module-prize-carousel" :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                          <el-carousel indicator-position="none" height="210px" :interval="5000"
                            v-if="prizeList?.length > 0">
                            <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                              <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                                :src="item.victory_img" />
                              <img v-else class="activity-module-prize-carousel-img"
                                src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                              <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </div>
                    <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                      <div class="activity-module-adv-bg">
                        <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img" />
                      </div>
                    </div>
                  </div>
                  <div class="activity-content-middle" ref="content"
                    :style="{ 'margin-right': mleft + 'px', 'margin-left': mleft + 'px' }"
                    v-if="this.acceptData.screen_type != 0">
                    <!-- 倒计时 -->
                    <div class="activity-count" ref="rainBefore" v-if="start">
                      <img :src="require('@/assets/redPackage/count.gif')"
                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                    </div>
                    <!-- 背景层 -->
                    <div class="activity-rain-bg" ref="rainBg"
                      :class="{ 'activity-rain-bgs': acceptData.screen_type != 0 && screen_part_type == 2 && fenshu == 'v' || acceptData.screen_type != 0 && screen_part_type == 3 && fenshu == 'v' }">
                    </div>
                    <!-- 历史中奖记录 -->
                    <div class="activity-history-bg" ref="rainHistory"></div>

                    <div class="activity-winner" ref="rainWinner"></div>
                  </div>
                  <div class="activity-content-side" v-if="mode == 'h' && !fullscreenLoading"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': (mleft + 10) + 'px' }">
                    <div class="activity-module-rank" :class="'rank' + rankType">
                      <ul>
                        <li v-for="(item, index) of userTop10" :key="index"
                          :class="{ 'active': item.id && index < 3 && rankType == 2 }">
                          <div v-if="rankType == 2"
                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                            <span class="rank-no" v-if="index < 9">{{ '0' + (index + 1) }}</span>
                            <span class="rank-no" v-else>10</span>
                          </div>
                          <img class="rank-head" :src="item.avatar" />
                          <div class="rank-info">
                            <p class="rank-info-name">{{ item.nickname }}</p>
                            <p v-if="rankType == 2">手速 {{ item.hand_speed }}</p>
                            <p v-if="rankType == 2">红包 {{ item.prize_count }}</p>
                            <p v-if="rankType == 1">获得： {{ item.victory_name }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                      <div class="activity-module-adv-bg">
                        <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img" />
                      </div>
                    </div>
                  </div>
                  <div v-if="mode == 'v'" class="activity-content-bottom" ref="bottom"
                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                    <div class="activity-content-bottom-item">
                      <div class="activity-content-info">
                        <div class="activity-module-qrcode"
                          :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                          <div class="activity-module-qrcode-item">
                            <img :src="acceptData.activity.qrcode_path" />
                          </div>
                        </div>
                        <div class="activity-module-rank" :class="'rank' + rankType">
                          <ul>
                            <li v-for="(item, index) of userTop10" :key="index"
                              :class="{ 'active': item.id && index < 3 && rankType == 2 }">
                              <div v-if="rankType == 2">
                                <span class="rank-no" v-if="index < 9">{{ '0' + (index + 1) }}</span>
                                <span class="rank-no" v-else>10</span>
                              </div>
                              <img class="rank-head" :src="item.avatar" />
                              <div class="rank-info">
                                <p class="rank-info-name">{{ item.nickname }}</p>
                                <div class="rank-info-process">
                                  <p v-if="rankType == 2">手速 <span>{{ item.hand_speed }}</span></p>
                                  <p v-if="rankType == 2">红包 <span>{{ item.prize_count }}</span></p>
                                  <p v-if="rankType == 1">获得 <span>{{ item.victory_name }}</span></p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="activity-module-prize">
                          <div class="activity-module-prize-carousel">
                            <el-carousel indicator-position="none" height="210px" :interval="5000"
                              v-if="prizeList.length > 0">
                              <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                                <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                                  :src="item.victory_img" />
                                <img v-else class="activity-module-prize-carousel-img"
                                  src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                                <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                              </el-carousel-item>
                            </el-carousel>
                          </div>
                        </div>
                      </div>
                      <div class="activity-content-ad">
                        <div class="activity-content-ad-item"
                          v-if="acceptData.activity.right_banner_img || acceptData.activity.right_banner_img">
                          <img :src="acceptData.activity.left_banner_img" v-if="acceptData.activity.left_banner_img" />
                          <img :src="acceptData.activity.right_banner_img" v-if="acceptData.activity.right_banner_img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div ref="content" v-if="this.acceptData.screen_type != 0">
                    <div class="mask_yindao heng hengs" v-if="mode == 'h'" ref="mask_yindao" :style="{ 'opacity': 0 }">
                      <div :class="['pop-' + 'show']">
                        <div class="mask_yindao_wrap">
                          <div class="mask_yindao_content"
                            :class="{ 'mt240': screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length >= 3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >= 3 }">
                            <img class="sao" src="../../../assets/card/sao.png"
                              :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                            <div class="sao_wrap sao_wrap_img"
                              :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                              <img class="code" :src="acceptData.activity.qrcode_path" />
                            </div>
                            <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                              :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                          </div>
                          <div class="layerLst_wrap"
                            :class="{ 'weizhi': screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length >= 3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >= 3 }"
                            :style="{ 'transform': prizeCountListT.length >= 3 ? 'translateX(' + (100 - (100 / screenRadio)) / 2 + '%)' : '', 'left': prizeCountListT.length >= 3 ? (100 - (100 / screenRadio)) / 2 + '%' : '', width: prizeCountListT.length >= 3 ? (100 / screenRadio) + '%' : '', 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                            <div v-for="(item, index) in prizeCountListT" :key="index">
                              <div class="victory_bg">
                                <img class="victory_img" :src="item.victory_img" />
                                <div class="victory_name">
                                  <p class="victory_text">{{ item.victory_name }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mask_yindao ver" v-if="mode == 'v' && this.prizeCountListT.length == 5" ref="mask_yindao"
                      :style="{ 'opacity': 0 }">
                      <div class="mask_yindao_wrap mask_yindao_wrap05"
                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                        <div class="mask_yindao_content">
                          <img class="sao" src="../../../assets/card/sao.png" />
                          <div class="sao_wrap">
                            <img class="code" :src="acceptData.activity.qrcode_path" />
                          </div>
                          <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                        </div>
                        <div class="layerLst_wrap layerLst_wrap05">
                          <div v-for="(item, index) in prizeCountListT" :key="index">
                            <div class="victory_bg">
                              <img class="victory_img" :src="item.victory_img" />
                              <div class="victory_name">
                                <p class="victory_text">{{ item.victory_name }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length == 4"
                      ref="mask_yindao" :style="{ 'opacity': 0 }">
                      <div class="mask_yindao_wrap mask_yindao_wrap04"
                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                        <div class="mask_yindao_content">
                          <img class="sao" src="../../../assets/card/sao.png" />
                          <div class="sao_wrap">
                            <img class="code" :src="acceptData.activity.qrcode_path" />
                          </div>
                          <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                        </div>
                        <div class="layerLst_wrap">
                          <div v-for="(item, index) in prizeCountListT" :key="index">
                            <div class="victory_bg">
                              <img class="victory_img" :src="item.victory_img" />
                              <div class="victory_name">
                                <p class="victory_text">{{ item.victory_name }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length < 4"
                      ref="mask_yindao" :style="{ 'opacity': 0 }">
                      <div class="mask_yindao_wrap mask_yindao_wrap03"
                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                        <div class="mask_yindao_content">
                          <img class="sao" src="../../../assets/card/sao.png" />
                          <div class="sao_wrap">
                            <img class="code" :src="acceptData.activity.qrcode_path" />
                          </div>
                          <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                        </div>
                        <div class="layerLst_wrap">
                          <div v-for="(item, index) in prizeCountListT" :key="index">
                            <div class="victory_bg">
                              <img class="victory_img" :src="item.victory_img" />
                              <div class="victory_name">
                                <p class="victory_text">{{ item.victory_name }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="this.acceptData.screen_type == 0">
      <div v-show="!fullscreenLoading" class="activity" ref="activity"
        :class="{ 'vertical': mode == 'v', 'horizontal': mode == 'h' }"
        :style="acceptData?.activity?.bottom_color && isOverTop ? { backgroundColor: acceptData?.activity?.bottom_color !== '' ? acceptData?.activity?.bottom_color : '' } : { backgroundImage: 'url(' + (mode == 'h' ? acceptData?.activity.bg_img : acceptData?.activity.bg_h_img) + ')' }">

        <div v-show="state != 'going' && acceptData" class="activity-start">
          <div class="activity-unstart">
            <div class="activity-begin">
              <a class="activity-btn" :class="{ 'finish': state == 'finish' }" href="javascript:;"></a>
            </div>
          </div>
        </div>
        <div v-show="state == 'going'" class="activity-container"
          :style="!!acceptData?.activity?.bottom_color && isOverTop ? { 'background-image': 'url(' + (mode == 'h' ? acceptData?.activity.bg_img : acceptData?.activity.bg_h_img) + ')' } : {}"
          ref="container" v-if="this.acceptData.screen_type == 0">
          <div class="activity-counter"
            v-if="JSON.stringify(countObj) != '[]' && (acceptData.activity && (acceptData.activity.online_show == 1 || acceptData.activity.join_show == 1) || acceptData.reside_show == 1 || acceptData.flow_show == 1)">
            <ul>
              <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.online_show == 1">
                <strong class="count-font green">{{ countObj.online || 0 }}</strong>
                <span>实时在线人数</span>
              </li>
              <li v-if="acceptData.current_select.current == 'activity' && acceptData.activity.join_show == 1">
                <strong class="count-font orange">{{ countObj.join || 0 }}</strong>
                <span>累计参与人数</span>
              </li>
              <li v-if="acceptData.reside_show == 1">
                <strong class="count-font yellow">{{ countObj.flow }}</strong>
                <span>驻留人数</span>
              </li>
              <li v-if="acceptData.flow_show == 1">
                <strong class="count-font purple">{{ countObj.pass }}</strong>
                <span>客流人次</span>
              </li>
            </ul>
          </div>

          <div class="activity-main">
            <div class="activity-wrapper www" ref="actWraps">
              <div class="activity-title">
                <img :src="acceptData.activity.title_img"
                  :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
              </div>
              <div class="activity-redpackage" ref="redNum">
                还剩 <span class="yellow1">{{ redNum }}</span> 个红包
              </div>
              <div class="activity-content-side" v-if="mode == 'h'">
                <div class="activity-module-left-bg z" v-if="acceptData && acceptData.activity.qrcode_img"
                  :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                  <div class="activity-module-qrcode"
                    :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                    <div class="activity-module-qrcode-item">
                      <img :src="acceptData.activity.qrcode_path" />
                    </div>
                  </div>
                  <div class="activity-module-prize" v-if="acceptData"
                    :style="{ 'background-image': 'url(' + acceptData.activity.prize_img + ')' }">
                    <div class="activity-module-prize-carousel" :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                      <el-carousel indicator-position="none" height="210px" :interval="5000" v-if="prizeList?.length > 0">
                        <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                          <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                            :src="item.victory_img" />
                          <img v-else class="activity-module-prize-carousel-img"
                            src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                          <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </div>
                <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                  <div class="activity-module-adv-bg">
                    <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img" />
                  </div>
                </div>
              </div>
              <div class="activity-content-middle" ref="content" v-if="this.acceptData.screen_type == 0">
                <div class="activity-count" ref="rainBefore" v-if="start">
                  <img :src="require('@/assets/redPackage/count.gif')" />
                </div>
                <div class="activity-rain-bg" ref="rainBg"></div>
                <div class="activity-history-bg" ref="rainHistory"></div>

                <div class="activity-winner" ref="rainWinner"></div>
              </div>
              <div class="activity-content-side" v-if="mode == 'h' && !fullscreenLoading">
                <div class="activity-module-rank" :class="'rank' + rankType">
                  <ul>
                    <li v-for="(item, index) of userTop10" :key="index"
                      :class="{ 'active': item.id && index < 3 && rankType == 2 }">
                      <div v-if="rankType == 2" :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                        <span class="rank-no" v-if="index < 9">{{ '0' + (index + 1) }}</span>
                        <span class="rank-no" v-else>10</span>
                      </div>
                      <img class="rank-head" :src="item.avatar" :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''" />
                      <div class="rank-info" :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                        <p class="rank-info-name">{{ item.nickname }}</p>
                        <p v-if="rankType == 2">手速 {{ item.hand_speed }}</p>
                        <p v-if="rankType == 2">红包 {{ item.prize_count }}</p>
                        <p v-if="rankType == 1">获得： {{ item.victory_name }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img"
                  :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''">
                  <div class="activity-module-adv-bg">
                    <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img" />
                  </div>
                </div>
              </div>
              <div v-if="mode == 'v'" class="activity-content-bottom" ref="bottom">
                <div class="activity-content-bottom-item">
                  <div class="activity-content-info">
                    <div class="activity-module-qrcode"
                      :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                      <div class="activity-module-qrcode-item">
                        <img :src="acceptData.activity.qrcode_path" />
                      </div>
                    </div>
                    <div class="activity-module-rank" :class="'rank' + rankType">
                      <ul>
                        <li v-for="(item, index) of userTop10" :key="index"
                          :class="{ 'active': item.id && index < 3 && rankType == 2 }">
                          <div v-if="rankType == 2">
                            <span class="rank-no" v-if="index < 9">{{ '0' + (index + 1) }}</span>
                            <span class="rank-no" v-else>10</span>
                          </div>
                          <img class="rank-head" :src="item.avatar" />
                          <div class="rank-info">
                            <p class="rank-info-name">{{ item.nickname }}</p>
                            <div class="rank-info-process">
                              <p v-if="rankType == 2">手速 <span>{{ item.hand_speed }}</span></p>
                              <p v-if="rankType == 2">红包 <span>{{ item.prize_count }}</span></p>
                              <p v-if="rankType == 1">获得 <span>{{ item.victory_name }}</span></p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="activity-module-prize">
                      <div class="activity-module-prize-carousel">
                        <el-carousel indicator-position="none" height="210px" :interval="5000"
                          v-if="prizeList.length > 0">
                          <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                            <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                              :src="item.victory_img" />
                            <img v-else class="activity-module-prize-carousel-img"
                              src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                            <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                  <div class="activity-content-ad">
                    <div class="activity-content-ad-item"
                      v-if="acceptData.activity.right_banner_img || acceptData.activity.right_banner_img">
                      <img :src="acceptData.activity.left_banner_img" v-if="acceptData.activity.left_banner_img"
                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                      <img :src="acceptData.activity.right_banner_img" v-if="acceptData.activity.right_banner_img"
                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                    </div>
                  </div>
                </div>
              </div>
              <div ref="content" v-if="this.acceptData.screen_type == 0">
                <div class="mask_yindao heng " v-if="mode == 'h'" ref="mask_yindao" :style="{ 'opacity': 0 }">
                  <div :class="['pop-' + 'show']">
                    <div class="mask_yindao_wrap">
                      <div class="mask_yindao_content">
                        <!-- <img class="sao" src="../../../assets/card/sao.png"
                                  :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'none' : ''" /> -->
                        <div class="sao_wrap"
                          :class="isscaleSuo == 8 || isscaleSuo == 9 ? 'sao_wrap_img' : 'sao_wrap_img'">
                          <img class="code" :src="acceptData.activity.qrcode_path" />
                        </div>
                        <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                      </div>
                      <div class="layerLst_wrap">
                        <div v-for="(item, index) in prizeCountListT" :key="index">
                          <div class="victory_bg">
                            <img class="victory_img" :src="item.victory_img" />
                            <div class="victory_name">
                              <p class="victory_text">{{ item.victory_name }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mask_yindao ver" v-if="mode == 'v' && this.prizeCountListT.length == 5" ref="mask_yindao"
                  :style="{ 'opacity': 0 }">
                  <div class="mask_yindao_wrap mask_yindao_wrap05">
                    <div class="mask_yindao_content">
                      <!-- <img class="sao" src="../../../assets/card/sao.png" /> -->
                      <div class="sao_wrap">
                        <img class="code" :src="acceptData.activity.qrcode_path" />
                      </div>
                      <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                    </div>
                    <div class="layerLst_wrap layerLst_wrap05">
                      <div v-for="(item, index) in prizeCountListT" :key="index">
                        <div class="victory_bg">
                          <img class="victory_img" :src="item.victory_img" />
                          <div class="victory_name">
                            <p class="victory_text">{{ item.victory_name }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length == 4" ref="mask_yindao"
                  :style="{ 'opacity': 0 }">
                  <div class="mask_yindao_wrap mask_yindao_wrap04">
                    <div class="mask_yindao_content">
                      <!-- <img class="sao" src="../../../assets/card/sao.png"  /> -->
                      <div class="sao_wrap">
                        <img class="code" :src="acceptData.activity.qrcode_path" />
                      </div>
                      <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                    </div>
                    <div class="layerLst_wrap">
                      <div v-for="(item, index) in prizeCountListT" :key="index">
                        <div class="victory_bg">
                          <img class="victory_img" :src="item.victory_img" />
                          <div class="victory_name">
                            <p class="victory_text">{{ item.victory_name }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length < 4" ref="mask_yindao"
                  :style="{ 'opacity': 0 }">
                  <div class="mask_yindao_wrap mask_yindao_wrap03">
                    <div class="mask_yindao_content">
                      <img class="sao" src="../../../assets/card/sao.png" />
                      <div class="sao_wrap">
                        <img class="code" :src="acceptData.activity.qrcode_path" />
                      </div>
                      <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                    </div>
                    <div class="layerLst_wrap">
                      <div v-for="(item, index) in prizeCountListT" :key="index">
                        <div class="victory_bg">
                          <img class="victory_img" :src="item.victory_img" />
                          <div class="victory_name">
                            <p class="victory_text">{{ item.victory_name }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import {
  getPrizeNum,
  getTopUserList,
  getHistoryPrizeUserList,
  getLastUserList,
  getPrizeList,
  getOverView,
  getPrizeNumListCard
} from '@/api/littleGameOne.js'
import { TweenMax } from 'gsap'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
let pageCurrent = "index";
let selectedActivity = "";
const placeholder = [
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  },
  {
    prize_count: '- -',
    nickname: "- - - -",
    avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
    hand_speed: '- -',
    victory_name: '- -'
  }
]
export default {
  name: "redPackage",
  data() {
    return {
      isscaleSuo: true,
      prizeCountListT: [],
      state: 'going',
      start: false,
      scale: 1, //缩放
      history: [],
      mode: "h",
      coll_id: "",
      acceptData: {
        current_select: {},
        activity: {}
      },
      count: 0,
      redNum: 0,
      userTop10: placeholder,
      prizeList: [],
      rankType: 1,
      page: 0,
      content_w: 0,
      newlyList: [],
      uniq: '',
      countObj: {
        online: 0,
        join: 0,
        flow: 0,
        pass: 0
      },
      selectedActivity: '',
      isOverTop: false,
      fullscreenLoading: false,
      timerArr: [],
      countRefresh: 0,
      wrapWidth: '',
      wrapHeight: '',
      bgx: '',
      reversed_width: '',
      reversed_height: '',
      scaleFen: 1,
      mleft: 0,
      mRight: 0,
      screen_part_type: 2,
      userLeft: 0,
      screenRadios: 1,
      screenRadio: 1,
      mt: 0,
      mTitile: 0,
      mConter: 0,
      mRed: 0,
      fenshu: 'h'
    };
  },
  mounted() {
    this.screen_type = localStorage.getItem('screen_type')
            let w = 0
            let h = 0
            if (this.screen_type == 9) {
                w = 1920
                h = 560
            } else if (this.screen_type == 8) {
                w = 1920
                h = 360
            } else if (this.screen_type == 7) {
                w = 1920
                h = 640
            } else if (this.screen_type == 6) {
                w = 1920
                h = 1080
            } else if (this.screen_type == 5) {
                w = 1600
                h = 1200
            } else if (this.screen_type == 4) {
                w = 1400
                h = 1400
            } else if (this.screen_type == 3) {
                w = 1000
                h = 1500
            } else if (this.screen_type == 2) {
                w = 1000
                h = 2000
            } else if (this.screen_type == 1) {//自定义
                w = localStorage.getItem('screen_width')
                h = localStorage.getItem('screen_length')
            } else if (this.screen_type == 0) {//默认自适应
                w = 1920
                h = 1080
                this.screen_part_type = 0
               
            }

            //横屏
            if (16 / 9 < w / h) {
                this.fenshu = 'h'
                this.wrapHeight = '100vh'
                this.wrapWidth = 16 / 9 * h + 'px'
                if (this.screen_type == 0) {//默认自适应
                    this.screenRadio = 1
                    this.mleft = 0
                } else {//3分|| 2分屏
                    this.screenRadio = 16 / 9 / (w / h)
                    this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
                }

            } else {
                //竖屏
                this.fenshu = 'v'
                this.wrapWidth = '100%'
                this.screenRadio = 1
                this.screenRadios = 9 / 16 * (w / h)
                console.log(this.screenRadios)
            }
            this.state = localStorage.getItem('state')
    let that = this
    setInterval(() => {
      that.open();
      setTimeout(() => {
        that.close()
      }, 5000)
    }, 10000)
    this.fullscreenLoading = true;
    this.coll_id = this.$route.params.id;
    this.resizeSettings()
  },
  methods: {
    resizeSettings() {
      const _w = document.body.clientWidth;
      const _h = document.body.clientHeight;
      this.mode = _w >= _h ? "h" : "v";
      if (this.mode == 'h') {
        const rate = _w > 1440 ? 1920 / 1080 : 1440 / 1080;
        this.isOverTop = rate < _w / _h;
        if (this.$refs.containerlayer) {
          const fixWidth = _w > 1440 ? 1920 : 1440;
          if (this.isOverTop) {
          } else {
            const currH = _h / (_w / fixWidth);
            const _style = this.$refs.containerlayer.getAttribute("style");

          }
        }
      } else {
        if (this.$refs.container) {
          const currH = (_h / (_w / 1380));
          const currW = (_w / (_w / 1380));
          // this.$refs.containerlayer.style.transform = "scale(" + _w / 1380 + ")";

        }
      }
    },
    open() {
      TweenMax.to(this.$refs.mask_yindao, 1, {
        opacity: 1,
      });
    },
    close() {
      TweenMax.to(this.$refs.mask_yindao, 1, {
        opacity: 0,
      });
    },
    getPrizeListCountT() {
      getPrizeNumListCard({ colle_uniq: this.coll_id, uniq: this.uniq, limit: 5 }).then((res) => {
        for (let i in res.data) {
          if (res.data[i].victory_level == 1) {
            res.data[i].victory_level_name = '一等奖'
          }
          if (res.data[i].victory_level == 2) {
            res.data[i].victory_level_name = '二等奖'
          }
          if (res.data[i].victory_level == 3) {
            res.data[i].victory_level_name = '三等奖'
          }
          if (res.data[i].victory_level == 4) {
            res.data[i].victory_level_name = '四等奖'
          }
          if (res.data[i].victory_level == 5) {
            res.data[i].victory_level_name = '五等奖'
          }
          if (res.data[i].victory_level == 6) {
            res.data[i].victory_level_name = '六等奖'
          }
          if (res.data[i].victory_level == 7) {
            res.data[i].victory_level_name = '七等奖'
          }
          if (res.data[i].victory_level == 8) {
            res.data[i].victory_level_name = '八等奖'
          }
          if (res.data[i].victory_level == 9) {
            res.data[i].victory_level_name = '九等奖'
          }
          if (res.data[i].victory_level == 10) {
            res.data[i].victory_level_name = '十等奖'
          }

        }
        this.prizeCountListT = res.data


      })
    },

    getParentData(data) {
      this.acceptData = { ...data };
      if (
        ((pageCurrent && pageCurrent != data.current_select.current) ||
          (selectedActivity && selectedActivity != data.current_select.selectValue))
      ) {
        if (this.countRefresh == 1) {
          window.location.reload();
        }
        this.countRefresh++
      }

      //表示活动未开始
      if (data.activity) {
        //显示活动
        const current = new Date().getTime();
        if (current < data.activity.begin_time * 1000) {
          this.state = 'unstart';
          this.count = 0;
        } else if (current > data.activity.end_time * 1000 || data.activity?.is_termi == 2) {
          this.state = 'finish';
          this.count = 0;
        } else {
          this.uniq = data.activity.uniq;
          this.state = 'going';
          this.isscaleSuo = data.activity.resolution

          this.screen_part_type = this.acceptData.screen_part_type
          let w = 0
          let h = 0
          if (this.acceptData.screen_type == 9) {
            w = 1920
            h = 560
          } else if (this.acceptData.screen_type == 8) {
            w = 1920
            h = 360
          } else if (this.acceptData.screen_type == 7) {
            w = 1920
            h = 640
          } else if (this.acceptData.screen_type == 6) {
            w = 1920
            h = 1080
          } else if (this.acceptData.screen_type == 5) {
            w = 1600
            h = 1200
          } else if (this.acceptData.screen_type == 4) {
            w = 1400
            h = 1400
          } else if (this.acceptData.screen_type == 3) {
            w = 1000
            h = 1500
          } else if (this.acceptData.screen_type == 2) {
            w = 1000
            h = 2000
          } else if (this.acceptData.screen_type == 1) {//自定义
            w = this.acceptData.screen_width
            h = this.acceptData.screen_length
          } else if (this.acceptData.screen_type == 0) {//默认自适应
            w = 1920
            h = 1080
            this.screen_part_type = 0
          } else if (this.acceptData.screen_type == 10) {//默认自适应
            w = 1920
            h = 1080
            this.screen_part_type = 0
          }

          //横屏
          if (16 / 9 <= w / h) {
            this.fenshu = 'h'
            this.wrapHeight = '100vh'
            this.wrapWidth = 16 / 9 * h + 'px'
            if (this.acceptData.screen_type == 0) {//默认自适应
              this.screenRadio = 1
              this.mleft = 0
            } else if (this.acceptData.screen_type == 10) {
              this.screenRadio = 9 / 16 * (this.acceptData.screen_width / this.acceptData.screen_length)
              console.log(this.screenRadio)
            }
            else {//3分|| 2分屏
              this.screenRadio = 16 / 9 / (w / h)
              this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
            }

          } else {
            this.fenshu = 'v'
            //竖屏
            this.wrapWidth = '100%'
            this.screenRadio = 1
            this.screenRadios = 9 / 16 * (w / h)
            if (this.$refs.activityWrap) {
              this.$refs.activityWrap.style.transform = "scaleY(" + this.screenRadios + ")";
            }

            if (this.acceptData.screen_part_type == 3) {//2分屏幕
              const _h = document.body.clientHeight;
              this.mt = (_h - (_h * this.screenRadios)) / this.screenRadios + 'px'
            } else if (this.acceptData.screen_part_type == 2) {//3分屏幕
              if (this.$refs.activityWrap) {
                //this.$refs.activityWrap.style.top = h*(1 - this.screenRadios)/2 +'px';
              }

            }
          }

          if (this.isscaleSuo == 8) {
            if (this.$refs.showpanel) {
              this.$refs.showpanel.style.transform = "scale(0.3, 0.9)";
            }
          }
          if (this.isscaleSuo == 9) {
            if (this.$refs.showpanel) {
              this.$refs.showpanel.style.transform = "scale(0.3, 0.8)";
            }
          }

        }
      } else {
        //显示首页        
        this.uniq = data.current_select.selectValue;
        this.state = 'going';
      }
      selectedActivity = data.current_select.selectValue;
      pageCurrent = data.current_select.current;

      if (this.state == 'going') {
        this.getPrizeNum();
        this.getOverViewNum(data.activity.uniq);
        this.getPrizeListCountT()
        setTimeout(() => {
          this.getHistoryRed();
        }, 3600);

        if (this.rankType == 1) {
          this.getLastRed();
        } else {
          this.getTopUser();
        }
      }
      if (this.count == 0 && this.state == 'going') {
        this.coll_id = this.$route.params.id;
        this.start = true;
        this.rankType = data.activity.add_info.red_type;
        //this.rankType = JSON.parse(data.activity.add_info)?.red_type;        
        console.log("开始", new Date().getTime())
        setTimeout(() => {
          console.log("执行", new Date().getTime())
          this.start = false;
          this.createFireWork(); // 烟花动效
          this.createRedPackage(12); //红包
        }, 3600);
        this.resizeSetting();
        window.addEventListener("resize", () => {
          this.resizeSetting();
        });
        this.count++;
      }
      this.getPrizeList();
      this.fullscreenLoading = false;

    },

    getOverViewNum(uniq) {
      getOverView({
        uniq: uniq,
        colle_uniq: this.coll_id,
      }).then((res) => {
        if (res.status == 200) {
          this.countObj = res.data;
        }

      })
    },
    // 获取红包剩余个数
    getPrizeNum() {
      getPrizeNum({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
        this.redNum = res.data.num;
      })
    },
    getTopUser() {
      getTopUserList({ colle_uniq: this.coll_id, uniq: this.acceptData.activity.uniq }).then((res) => {
        this.formatterData(res.data);
      })
    },
    getHistoryRed() {
      getHistoryPrizeUserList({ colle_uniq: this.coll_id, uniq: this.acceptData.activity.uniq }).then((res) => {
        if (this.history.length == 0) {
          this.newlyList = res.data.reverse();
          this.history = res.data;
        }
        if (res.data.length != this.history.length) {
          const newCount = res.data.length - this.history.length;
          this.newlyList = res.data.slice(0, newCount);
          this.history = res.data;
          this.page = 0;
        }

        if (this.newlyList.length > this.page * 10) {
          this.createHistoryRed(this.setPagination(this.newlyList, this.page, 10));
        }
        if (this.newlyList.length > this.page * 6) {
          this.createWinner(this.setPagination(this.newlyList, this.page, 6));
        }
        this.page++;
      })
    },
    getLastRed() {
      getLastUserList({ colle_uniq: this.coll_id, uniq: this.acceptData.activity.uniq }).then((res) => {
        this.formatterData(res.data);
      })
    },
    formatterData(data) {
      const list = [];
      for (let i = 0; i < 10; i++) {
        if (i >= data.length) {
          list.push({
            prize_count: '- -',
            nickname: "- - - -",
            avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
            hand_speed: '- -',
            victory_name: '- -'
          })
        } else {
          list.push(data[i])
        }
      }
      this.userTop10 = list;
    },
    getPrizeList() {
      getPrizeList({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
        if (res.length !== this.prizeList.length) {
          this.prizeList = res.data;
        }

      })
    },
    createFireWork() {
      for (let i = 0; i < 2; i++) {
        const fire = document.createElement("div");
        fire.className = "firework-" + (i + 1);
        if (this.$refs.rainBg) {
          this.$refs.rainBg.appendChild(fire);
        }
      }
    },
    createRedPackage(num) {
      for (let i = 0; i < num; i++) {
        const wrap = document.createElement("div");
        console.log(wrap + 'wrap')
        const _img = document.createElement("img");
        const hb = this.getRandNum(1, 2);

        wrap.className = "red-packet";
        if (i % 2) {
          wrap.classList.add("middle");
        } else {
          wrap.classList.add("small");
        }

        _img.src = require('@/assets/redPackage/hongbao_' + hb + '.png');

        wrap.appendChild(_img);
        if (this.$refs.rainBg) {
          this.$refs.rainBg.appendChild(this.setAnimate(wrap));
        }
      }
    },
    createHistoryRed(list) {
      for (let i = 0; i < list.length; i++) {
        const time = (this.page * 10 + i) * 200 + this.getRandNum(1000, 2000);
        setTimeout(() => {
          this.createRedItem(list[i]);
        }, time);
      }
    },
    createRedItem(item) {
      const wrap = document.createElement("div");
      const _hb = document.createElement("div");
      wrap.className = "red-packet";
      _hb.className = "red-inner"
      // _hb.innerHTML = "<img class='userhead' src='"+ item.avatar +"'/> <p class='username'>"+ item.nickname +"</p>";
      wrap.appendChild(_hb);
      const node = this.setAnimate(wrap, 1);
      this.$refs.rainHistory.appendChild(node);
    },
    createWinner(list) {
      const randArr = this.getUniqueRand(1, 6, list.length);
      for (let i = 0; i < list.length; i++) {
        const time = (this.page * 6 + i) * 2000 + this.getRandNum(2000, 6000);
        this.timerArr[i] = setTimeout(() => {
          this.createWinnerItem(list[i], randArr[i]);
        }, time);
      }
    },

    createWinnerItem(item, index) {

      const wrap = document.createElement("div");

      wrap.classList.add('show-panel', 'panel-' + index, 'show');
      wrap.innerHTML = `<div class="show-panel-container" ref="showpanel">
              <div class="prize-info">
                <img class="prize-img" src="${item.victory_img}"  src="${item.victory_img}"/>
                <p class="prize-name">${item.victory_name}</p>
              </div>
              <div class="user-info">
                <img class="user-head" src="${item.avatar}"/>
                <p class="user-name">${item.nickname}</p>
              </div>
            </div> `;
      if (this.fenshu == 'h') {
        wrap.style.transform = "scaleX(" + this.screenRadio + ")";
      } else {
        wrap.style.transform = "scaleY(" + this.screenRadio + ")";
      }

      this.$refs.rainWinner.appendChild(wrap);

      setTimeout(() => {
        this.$refs.rainWinner.removeChild(wrap);
      }, 4500);
    },

    setPagination(list, page, pageSize) {
      const curPage = page * pageSize;
      const curSize = (page + 1) * pageSize
      const pageList = list.slice(curPage, curSize);
      return pageList;
    },
    setAnimate(target, type) {
      const children = target.children[0];
      const opacity = this.getRandNum(0.3, .8, 1);
      const rot = this.getRandNum(-70, 70) + "deg";
      // const left = this.getRandNum(20, this.content_w - 100);
      const left = this.getRandNum(5, 80, 2);
      const duration = type == 1 ? this.getRandNum(2.6, 5, 1) : this.getRandNum(1.2, 3, 1);
      const delay = type == 1 ? this.getRandNum(0.5, 5, 1) : this.getRandNum(0, 3, 1);
      if (this.fenshu == 'h' && this.acceptData.screen_type == 0) {
        children.style.transform = "rotate(" + rot + ") scaleX(" + this.screenRadio + ")";
      } else {
        children.style.transform = "rotate(" + rot + ") scaleY(" + this.screenRadio + ")";
      }

      if (type != 1 && this.getRandNum(0, 10) % 3 == 0) {
        children.style.opacity = .3;
      }

      target.style.opacity = opacity;
      target.style.left = left + "%";
      target.style.animationDuration = duration + "s";
      target.style.animationDelay = delay + "s";

      if (type == 1) {
        setTimeout(() => {
          this.$refs.rainHistory.removeChild(target);
        }, parseFloat(duration) * 1000 + parseFloat(delay) * 1000);
      }
      return target
    },
    getRandNum(minNum, maxNum, decimalNum) {
      let max = 0, min = 0;
      minNum <= maxNum ? (min = minNum, max = maxNum) : (min = maxNum, max = minNum);
      switch (arguments.length) {
        case 1:
          return Math.floor(Math.random() * (max + 1));
        case 2:
          return Math.floor(Math.random() * (max - min + 1) + min);
        case 3:
          return (Math.random() * (max - min) + min).toFixed(decimalNum);
        default:
          return Math.random();
      }
    },
    getUniqueRand(min, max, num) {
      const randArr = [];
      for (let i = 0; i < num; i++) {
        const rand = this.getRandNum(min, max);
        if (randArr.indexOf(rand) == -1) {
          randArr.push(rand);
        } else {
          i--;
        }
      }
      return randArr
    },
    resizeSetting() {
      const _w = document.body.clientWidth;
      const _h = document.body.clientHeight;
      if (this.acceptData.screen_type != 10) {
        this.mode = _w >= _h ? "h" : "v";
      }  else if (this.acceptData.screen_type==1) {
        this.mode = _w >= _h ? "h" : "h";
      }


      if (this.mode == 'v') {
        let space = 20;
        if (_w <= 1000) {
          space = 10
        }
        if (_w <= 500) {
          space = 6
        }
        let _scale = (_w - space * 2) / 1569;
        this.mTitile = -(225 * _scale * (1 - this.screenRadio) / 2) + "px"
        this.mConter = -(32.33 / this.screenRadio - 32.33) / 2 + "px"
        this.mRed = -(50 * _scale * (1 - this.screenRadio) / 2) + "px"

        this.$refs.container.style.transform = "none";
        this.$refs.container.style.marginTop = "0px";
        this.$refs.container.style.marginLeft = "0px";
        this.$refs.container.style.maxWidth = _w + "px";
        this.$refs.container.style.minWidth = _w + "px";

        if (this.acceptData.screen_type != 0) {
          this.$refs.actWrap.style.transform = "scale(" + _scale + ")";
          this.$refs.actWrap.style.transformOrigin = "top left";
          this.$refs.actWrap.style.width = "1569px";
          this.$refs.actWrap.style.height = (_h / ((_w - space * 2) / 1569)) + "px";
        }else{
          this.$refs.actWraps.style.transform = "scale(" + _scale + ")";
          this.$refs.actWraps.style.transformOrigin = "top left";
          this.$refs.actWraps.style.width = "1569px";
          this.$refs.actWraps.style.height = (_h / ((_w - space * 2) / 1569)) + "px";
        }
        this.$refs.content.style.height = (_h / ((_w - space * 2) / 1569)) - (this.acceptData.activity.right_banner_img || this.acceptData.activity.right_banner_img ? 550 : 370) + "px";
        this.$refs.content.style.overflow = "hidden";
        this.$nextTick(() => {
          if (this.$refs.counter) {
            const _counter_w = this.$refs.counter.clientWidth;
            this.$refs.counter.style.transform = "scale(" + ((_w / 3) / _counter_w) + ")";
            this.$refs.counter.style.transformOrigin = "top left";
            if (_w < 876) {
              const _rate = ((_w / 5) / 208);
              this.$refs.redNum.style.transform = "scale(" + _rate + ") translateX(-50%)";
              this.$refs.redNum.style.transformOrigin = "top";
              this.$refs.redNum.style.marginLeft = - (208 - (208 * _rate)) / 2 + "px";
            }
          }
        })

        if (1080 / 1920 < _w / _h) {
          this.$refs.activity.style.backgroundSize = "100% auto";
        } else {
          this.$refs.activity.style.backgroundSize = "auto 100%";
        }

      } else {
        const rate = 1880 / 1000;

        if (this.acceptData.screen_type != 0) {
          this.$refs.actWrap.style.transform = "none";
        this.$refs.actWrap.style.width = "auto";
        this.$refs.actWrap.style.height = "auto";
        this.$refs.content.style.overflow = "visible"
        }else{
          this.$refs.actWraps.style.transform = "none";
          this.$refs.actWraps.style.width = "auto";
          this.$refs.actWraps.style.height = "auto";
          this.$refs.content.style.overflow = "visible"
        }
       ;

        if (this.$refs.counter) {
          this.$refs.counter.removeAttribute("style");
        }

        if (this.$refs.redNum) {
          this.$refs.redNum.removeAttribute("style");
        }

        this.isOverTop = rate < _w / _h;
        if (this.isOverTop) {
          this.$refs.container.style.transform = "scale(" + _h / 1000 + ")";
          if (this.fenshu == 'h' && this.screen_part_type == 3) {
            this.$refs.container.style.marginLeft = 0
            this.$refs.container.style.marginTop = 0
          } else {
            this.$refs.container.style.marginLeft = -(1880 - _w) / 2 + "px";
            this.$refs.container.style.marginTop = -((1000 - _h) / 2 - 1) + "px";
          }
        } else {
          this.$refs.container.style.transform = "scale(" + _w / 1880 + ")";
          console.log(_w / 1880)
          this.$refs.mask_yindao.style.height = _h/(_w / 1880)+ 'px'
          console.log(this.$refs.content.style.height)
          if (this.fenshu == 'h' && this.screen_part_type == 3) {
            this.$refs.container.style.marginLeft =
            this.$refs.container.style.marginTop = 0
          } else {
            this.$refs.container.style.marginLeft = -(1880 - _w) / 2 + "px";
            this.$refs.container.style.marginTop = ((((_h - 1000) / 2) - (_h - (1000 * (_w / 1880))) / 2) - 1) + "px";
          }
        }

        if (1880 / 1129 < _w / _h) {
          this.$refs.activity.style.backgroundSize = "100% auto";
        } else {
          this.$refs.activity.style.backgroundSize = "auto 100%";
        }
    
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url('./style.less');
</style>

<style lang="less">
.weizhi {
  position: absolute;
}

.mt240 {
  margin-top: -240px;
}

.none {
  opacity: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

//烟花
.firework-1 {
  width: 110px;
  height: 110px;
  position: absolute;
  left: 100px;
  top: 500px;
  animation-name: firework;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(8);
  background-size: 100% 100%;
}

.firework-2 {
  width: 110px;
  height: 110px;
  position: absolute;
  transform: scale(.7);
  right: 250px;
  top: 400px;
  animation-name: firework;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(8);
  background-size: 100% 100%;
}



@keyframes firework {
  0% {
    opacity: 1;
    background: url('../../../assets/redPackage/fire1.png')
  }

  10% {
    background: url('../../../assets/redPackage/fire2.png')
  }

  20% {
    background: url('../../../assets/redPackage/fire3.png')
  }

  30% {
    background: url('../../../assets/redPackage/fire4.png')
  }

  40% {
    background: url('../../../assets/redPackage/fire5.png')
  }

  50% {
    background: url('../../../assets/redPackage/fire6.png')
  }

  60% {
    background: url('../../../assets/redPackage/fire7.png')
  }
}

//红包雨背景

.red-packet {
  position: absolute;
  will-change: transform, opacity;
  -webkit-animation: anim 2s ease-in infinite;
  animation: anim 2s ease-in infinite;
  top: -300px;
}

.activity-rain-bg {
  position: relative;
}

.activity-rain-bgs {
  position: absolute;
  top: -200%;
  width: 100%;
}

.activity-rain-bg .red-packet {
  width: 142px;
  height: 214px;

  img {
    max-width: 100%;
  }
}

.activity-rain-bg .red-packet.middle {
  width: 100px;
  height: 156px
}

.activity-rain-bg .red-packet.small {
  width: 72px;
  height: 101px
}

@keyframes anim {
  0% {
    opacity: 0
  }

  20% {
    opacity: .8
  }

  80% {
    opacity: .8
  }

  to {
    transform: translateY(2990px);
    opacity: 0
  }
}

.activity-history-bg {
  .red-inner {
    //background-image: url("../../../assets/redPackage/hb_1.png");
    width: 170px;
    height: 225px;
    background-size: 100% 100%;

    .userhead {
      background-color: #FFC11C;
      padding: 4px;
      border-radius: 50px;
      position: absolute;
      left: 55px;
      top: 76px;
      width: 50px;
      height: 50px;
    }

    .username {
      position: absolute;
      left: 0;
      top: 120px;
      width: 100%;
      font-weight: 600;
      color: #FFC487;
      line-height: 22px;
      font-size: 16px;
      text-align: center;
    }
  }
}

//中奖框
.show-panel {
  position: absolute;
  background-image: url("../../../assets/redPackage/redPackage_active_bg.png");
  background-size: 100% 100%;
  width: 290px;
  height: 352px;
  padding: 80px 50px;
  box-sizing: border-box;
  opacity: 0;
  z-index: 999999999;

  .show-panel-container {
    -webkit-animation: textShowUp 1.2s ease-out .2s both;
    animation: textShowUp 1.2s ease-out .2s both
  }

  .prize-info {
    text-align: center;

    .prize-img {
      width: 70px;
      height: 70px;
    }

    .prize-name {
      color: #5F5F5F;
      font-size: 13px;
      display: block;
      min-height: 20px;
      margin: 0 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .user-info {
    text-align: center;
    margin-top: 20px;

    .user-head {
      background-color: #FFC11C;
      padding: 4px;
      border-radius: 50px;
      position: relative;
      top: 6px;
      width: 50px;
      height: 50px;
    }

    .user-name {
      color: #FFC487;
      display: block;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  &.show {
    -webkit-animation: showUp 3.6s linear both;
    animation: showUp 3.6s linear both;
  }

  &.panel-1 {
    top: 30%;
    left: 6%;
  }

  &.panel-2 {
    top: 24%;
    left: 33%;
  }

  &.panel-3 {
    top: 25%;
    left: 64%;
  }

  &.panel-4 {
    top: 62%;
    left: 13%;
  }

  &.panel-5 {
    top: 50%;
    left: 42%;
  }

  &.panel-6 {
    top: 60%;
    left: 60%;
  }
}


@keyframes showUp {
  0% {
    //transform:scale(.6);
  }

  10% {
    // transform:scale(1);
    opacity: 1
  }

  88% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes textShowUp {
  0% {
    opacity: 0
  }

  20% {
    opacity: 1
  }
}

.vertical {
  .panel-4 {
    top: 42%;
  }

  .panel-5 {
    top: 46%;
  }

  .panel-6 {
    top: 42%;
  }

  .activity-count {
    img {
      top: 50%
    }
  }
}

//适配屏幕1920*360
.scaleSuo {
  transform: scale(0.3, 0.9);
}

.scaleSuo9 {
  transform: scale(0.3, 0.8);
}

.mt90 {
  margin-top: -90px !important;
}

.mt50 {
  margin-top: -50px !important;
}

.sao_wrap_img .code {
  width: 560px;
  height: 560px;
}

.mCenter {
  margin: 0 auto;
}


.dingwei {
  position: absolute;
}

.resopnse {
  position: absolute;
  top: 0
}

.h100 {
  height: 100vh;
}

.pd40 {
  padding: 0 20px;
}</style>