<template>
    <div>
        <div class="fullscreenLoading" v-show="fullscreenLoading">
            <div :style="{ 'transform': fenshu == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadios + ')') }"
                class="wrap_full">
                <img v-if="mode == 'h'" :src="require('@/assets/moonBetting/loading_h.png')" class="img_card" />
                <img v-else :src="require('@/assets/moonBetting/loading_v.png')" class="img_card" />
            </div>
        </div>
        <div class="activity-bg-h"></div>
        <div class="activity" v-if="acceptData?.activity" v-show="!fullscreenLoading">
            <div v-if="acceptData.screen_type != 0">
                <img :src="acceptData?.adv_bottom_img"
                    style="position: absolute;top:0;bottom:0;left:0;right:0;width: 100%;height: 100%;"
                    v-if="this.acceptData.screen_type != 0" />
                <div v-else
                    style="height: 100vh; position: relative;background-repeat: no-repeat!important;background-position: top!important;"
                    :style="{ 'background-color': mode == 'v' ? acceptData?.activity?.bottom_color : '', 'background-image': mode == 'h' ? 'url(' + acceptData?.activity.bg_img + ')' : '' }">
                </div>
            </div>
            <div v-else>
                <div style="height: 100vh; background-size: 100% auto;position: relative;background-repeat: no-repeat!important;background-position: top!important;"
                    :style="{ 'background-color': acceptData?.activity?.bottom_color, 'background-image': mode == 'h' ? 'url(' + acceptData?.activity.bg_img + ')' : 'url(' + acceptData?.activity.bg_h_img + ')' }">
                </div>
            </div>
            <div class="bg_wrap" v-if="state != 'going' && state == 'unstart' || state != 'going' && state == 'finish'">
                <div v-if="state != 'going' && state == 'unstart'" class="activity-start"
                    :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }"
                    :style="{ 'transform': fenshu == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadios + ')') }">
                    <img :src="require('@/assets/card/start_bg.png')" class="img_card" />
                </div>

                <div v-if="state != 'going' && state == 'finish'" class="activity-finish"
                    :class="{ 'horizontal': mode == 'h', 'vertical': mode == 'v' }"
                    :style="{ 'transform': fenshu == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadios + ')') }">
                    <img :src="require('@/assets/card/end_bg.png')" class="img_card" />
                </div>
            </div>

            <div class="activity-wrap" :style="{ 'width': wrapWidth, 'height': wrapHeight,'transform': fenshu == 'v' ? ('scaleY(' + screenRadioY + ')') :'' }"
                :class="{ 'mCenter': mode == 'h' && screen_part_type == 2, 'dingwei': mode == 'v', 'resopnse': this.acceptData.screen_type == 0 }"
                ref="activityWrap">
                <div :class="{ 'liangfen': screen_part_type == 3 && fenshu == 'v' }"
                    :style="{ 'top': acceptData.screen_type != 0 && screen_part_type == 3 && fenshu == 'v' ? mt : '' }">
                    <div class="activityResponse" v-show="state == 'going'" ref="container" style="background-size: cover;"
                        :class="{ 'activity-container': acceptData.screen_type == 0, 'horizontal': mode == 'h', 'vertical': mode == 'v', 'no_adv': !acceptData.activity.left_banner_img }"
                        :style="{ 'background-color': acceptData.screen_type != 0 ? acceptData?.activity?.bottom_color : '', 'background-image': acceptData.screen_type != 0 ? 'url(' + acceptData?.activity.bg_img + ')' : '' }">
                        <div class="activity-bg"
                            :style="{ 'background-image': 'url(' + (mode == 'v' ? acceptData?.activity.bg_h_img : '') + ')' }">
                            <div class="activity-counter" ref="counter"
                                v-if="JSON.stringify(countObj) != '[]' && (acceptData.activity && (acceptData.activity.online_show == 1 || acceptData.activity.join_show == 1) || acceptData.reside_show == 1 || acceptData.flow_show == 1)">
                                <ul>
                                    <li :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }"
                                        v-if="acceptData.current_select.current == 'activity' && acceptData.activity.online_show == 1">
                                        <strong class="count-font green">{{ countObj.online || 0 }}</strong>
                                        <span>实时在线人数</span>
                                    </li>
                                    <li :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }"
                                        v-if="acceptData.current_select.current == 'activity' && acceptData.activity.join_show == 1">
                                        <strong class="count-font orange">{{ countObj.join || 0 }}</strong>
                                        <span>累计参与人数</span>
                                    </li>
                                    <li v-if="acceptData.reside_show == 1"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }">
                                        <strong class="count-font yellow">{{ countObj.flow }}</strong>
                                        <span>驻留人数</span>
                                    </li>
                                    <li v-if="acceptData.flow_show == 1"
                                        :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(85 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(85 * (1 - screenRadio) / 2 + 10) + 'px' }">
                                        <strong class="count-font purple">{{ countObj.pass }}</strong>
                                        <span>客流人次</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="activity-main">
                                <div class="activity-content-side" v-if="mode == 'h'"
                                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-left': mleft + 'px' }">
                                    <div class="activity-module-qrcode" v-if="acceptData && acceptData.activity.qrcode_img"
                                        :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                                        <div class="activity-module-qrcode-item">
                                            <img :src="acceptData.activity.qrcode_path" />
                                        </div>
                                    </div>
                                    <div class="activity-module-prize" v-if="!!acceptData"
                                        :style="{ 'background-image': 'url(' + acceptData.activity.prize_img + ')' }">
                                        <div class="activity-module-prize-carousel">
                                            <el-carousel indicator-position="none" :interval="5000"
                                                v-if="prizeList.length > 0">
                                                <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                                                    <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                                                        :src="item.victory_img" />
                                                    <img v-else class="activity-module-prize-carousel-img"
                                                        src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                                                    <p class="activity-module-prize-carousel-title">{{ item.victory_name }}
                                                    </p>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                    </div>

                                    <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                                        <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img" />
                                    </div>
                                </div>
                                <div class="activity-content-middle"
                                    :style="{ 'margin-right': mleft + 'px', 'margin-left': mleft + 'px' }">
                                    <div class="activity-title">
                                        <img :src="acceptData.activity.title_img"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                                    </div>
                                    <div class="activity-content-item" ref="content">
                                        <ul class="activity-user-box" ref="box"
                                            :class="{ 'ml40': acceptData.activity.screen_type != 0 }">
                                            <li :class="{ 'win': item.win_type == 1, 'win1': item.win_type > 0 && item.win_type < 13 }"
                                                :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }"
                                                v-for="item of userList" :key="item.id">
                                                <img class="user-head" :src="item.avatar" />
                                                <p class="user-name" :class="{ 'userW': this.acceptData.screen_type == 8 }"
                                                    :style="{ 'margin-left': this.acceptData.screen_type == 8 ? -(110 * screenRadio / 2 + 4) + 'px' : '' }">
                                                    {{ item.nickname }}</p>
                                                <div :class="['user-prz', item.win_type == 0 ? 'no-prz' : '', this.acceptData.screen_type == 8 || this.acceptData.screen_type == 9 || this.acceptData.screen_type == 7 ? 'usernameW' : '']"
                                                    :style="{ 'margin-left': this.acceptData.screen_type == 8 || this.acceptData.screen_type == 9 || this.acceptData.screen_type == 7 ? -(110 * screenRadio / 2 + 4) + 'px' : '' }">
                                                    <div class="user-prz-tou"
                                                        :class="{ 'usernameW': this.acceptData.screen_type == 8 || this.acceptData.screen_type == 9 || this.acceptData.screen_type == 7 }">
                                                        <i v-for="(it, idx) of item.points.sort()" :key="idx"
                                                            :class="`tou${it}`"></i>
                                                    </div>
                                                    <div
                                                        :class="['user-prz-name', item.win_type > 0 && item.win_type < 13 ? ('prz' + item.win_type) : '']">
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="activity-content-prize">
                                        <ul class="prize-list">
                                            <li v-for="(item, index) of prizeCountList" :key="index"
                                                :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': -(113 * (1 - screenRadio) / 2 + 10) + 'px', 'margin-left': -(113 * (1 - screenRadio) / 2 + 10) + 'px' }">
                                                <el-carousel indicator-position="none" v-if="item.length > 0"
                                                    :interval="5000"
                                                    :class="{ 'maxWdith': acceptData.activity.screen_type != 0 && fenshu == 'h' }">
                                                    <el-carousel-item v-for="(it, idx) in item" :key="idx">
                                                        <img v-if="it.victory_img" class="prize-list-img"
                                                            :src="it.victory_img" />
                                                        <img v-else class="prize-list-img"
                                                            src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                                                        <p :class="['prize-list-name', 'prz' + index]">&nbsp;</p>
                                                        <span class="prize-list-count">剩<i>{{ it.count || 0 }}</i>份</span>
                                                    </el-carousel-item>
                                                </el-carousel>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="activity-content-side" v-if="mode == 'h'"
                                    :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'), 'margin-right': mleft + 'px' }">
                                    <div class="activity-module-rank">
                                        <ul>
                                            <li v-for="(item, index) of topUserList" :class="{ 'win': item.win_type > 0 }"
                                                :key="index">
                                                <img class="rank-head" :src="item.avatar" />
                                                <div class="rank-info">
                                                    <p class="rank-info-name">{{ item.nickname }}</p>
                                                    <div class="rank-info-process">
                                                        <p>摇中 <span>{{ item.win_name }}</span></p>
                                                        <p>获得 <span>{{ item.victory_name }}</span></p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                                        <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img" />
                                    </div>
                                </div>
                                <div class="activity-content-bottom" v-if="mode == 'v'">
                                    <div class="activity-module-qrcode" v-if="acceptData && acceptData.activity.qrcode_img"
                                        :style="{ 'background-image': 'url(' + acceptData.activity.qrcode_img + ')' }">
                                        <div class="activity-module-qrcode-item">
                                            <img :src="acceptData.activity.qrcode_path" />
                                        </div>
                                    </div>
                                    <div class="activity-module-rank">
                                        <ul>
                                            <li v-for="(item, index) of topUserList" :key="index">
                                                <img class="rank-head" :src="item.avatar" />
                                                <div class="rank-info">
                                                    <p class="rank-info-name">{{ item.nickname }}</p>
                                                    <div class="rank-info-process">
                                                        <p>摇中 <span>{{ item.win_name }}</span></p>
                                                        <p>获得 <span>{{ item.victory_name }}</span></p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="activity-module-prize" v-if="!!acceptData"
                                        :style="{ 'background-image': 'url(' + acceptData.activity.prize_img + ')' }">
                                        <div class="activity-module-prize-carousel">
                                            <el-carousel indicator-position="none" :interval="5000"
                                                v-if="prizeList.length > 0">
                                                <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                                                    <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                                                        :src="item.victory_img" />
                                                    <img v-else class="activity-module-prize-carousel-img"
                                                        src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                                                    <p class="activity-module-prize-carousel-title">{{ item.victory_name }}
                                                    </p>
                                                </el-carousel-item>
                                            </el-carousel>
                                        </div>
                                    </div>
                                </div>
                                <div class="activity-adv"
                                    v-if="mode == 'v' && acceptData.activity.left_banner_img && acceptData.activity.right_banner_img">
                                    <div class="activity-module-adv" v-if="acceptData.activity.left_banner_img">
                                        <img class="activity-module-adv-img" :src="acceptData.activity.left_banner_img" />
                                    </div>
                                    <div class="activity-module-adv" v-if="acceptData.activity.right_banner_img">
                                        <img class="activity-module-adv-img" :src="acceptData.activity.right_banner_img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 最新中奖 -->
                        <div class="mask" ref="mask" :style="{ 'opacity': 0 }">
                            <div class="pop-winner"
                                :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                <div v-for="item of newList" :key="item.id" :class="['pop-' + item.index, 'show']">
                                    <transition enter-active-class="animated bounceIn"
                                        leave-active-class="animated bounceOut" :duration="600">
                                        <div class="winner-item">
                                            <div class="winner-head">
                                                <img :src="item.avatar" />
                                            </div>
                                            <div class="winner-body">
                                                <div class="winner-name">{{ item.nickname }}</div>
                                                <div class="winner-prz">
                                                    <div
                                                        :class="['winner-prz-num', item.win_type > 0 ? ('prz' + item.win_type) : '']">
                                                    </div>
                                                    <div class="winner-prz-tou">
                                                        <i v-for="(it, id) of item.points.sort()" :key="id"
                                                            :class="`tou${it}`"></i>
                                                    </div>
                                                </div>
                                                <div class="winner-prz-info" v-if="item.victory_name">
                                                    <img v-if="item.victory_img" class="winner-prz-info-img"
                                                        :src="item.victory_img" />
                                                    <img v-else class="winner-prz-info-img"
                                                        src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b" />
                                                    <div class="winner-prz-info-item">
                                                        <p class="name">获得奖品</p>
                                                        <p class="desc">{{ item.victory_name }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                        <div class="mask_yindao heng" v-if="mode == 'h'" ref="mask_yindao" :style="{ 'opacity': 0 }">
                            <div :class="['pop-' + 'show']">
                                <div class="mask_yindao_wrap">
                                    <div class="mask_yindao_content"
                                        :class="{ 'mt240': screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length >= 3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >= 3 }">
                                        <img class="sao" src="../../../assets/card/sao.png"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                                        <div class="sao_wrap sao_wrap_img"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                            <img class="code" :src="acceptData.activity.qrcode_path" />
                                        </div>
                                        <img class="layer_prize" src="../../../assets/card/layer_prizes.png"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }" />
                                    </div>
                                    <div class="layerLst_wrap"
                                        :class="{ 'weizhi': screen_part_type == 2 && fenshu == 'h' && prizeCountListT.length >= 3 || screen_part_type == 3 && fenshu == 'h' && prizeCountListT.length >= 3 }"
                                        :style="{ 'transform': prizeCountListT.length >= 3 ? 'translateX(' + (100 - (100 / screenRadio)) / 2 + '%)' : '', 'left': prizeCountListT.length >= 3 ? (100 - (100 / screenRadio)) / 2 + '%' : '', width: prizeCountListT.length >= 3 ? (100 / screenRadio) + '%' : '', 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }">
                                        <div v-for="(item, index) in prizeCountListT" :key="index">
                                            <div class="victory_bg">
                                                <img class="victory_img" :src="item.victory_img" />
                                                <div class="victory_name">
                                                    <p class="victory_text">{{ item.victory_name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mask_yindao ver" v-if="mode == 'v' && this.prizeCountListT.length == 5"
                            ref="mask_yindao" :style="{ 'opacity': 0 }">
                            <div class="mask_yindao_wrap mask_yindao_wrap05">
                                <div class="mask_yindao_content">
                                    <img class="sao" src="../../../assets/card/sao.png" />
                                    <div class="sao_wrap">
                                        <img class="code" :src="acceptData.activity.qrcode_path" />
                                    </div>
                                    <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                                </div>
                                <div class="layerLst_wrap layerLst_wrap05">
                                    <div v-for="(item, index) in prizeCountListT" :key="index">
                                        <div class="victory_bg">
                                            <img class="victory_img" :src="item.victory_img" />
                                            <div class="victory_name">
                                                <p class="victory_text">{{ item.victory_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length == 4"
                            ref="mask_yindao" :style="{ 'opacity': 0 }">
                            <div class="mask_yindao_wrap mask_yindao_wrap04">
                                <div class="mask_yindao_content">
                                    <img class="sao" src="../../../assets/card/sao.png" />
                                    <div class="sao_wrap">
                                        <img class="code" :src="acceptData.activity.qrcode_path" />
                                    </div>
                                    <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                                </div>
                                <div class="layerLst_wrap">
                                    <div v-for="(item, index) in prizeCountListT" :key="index">
                                        <div class="victory_bg">
                                            <img class="victory_img" :src="item.victory_img" />
                                            <div class="victory_name">
                                                <p class="victory_text">{{ item.victory_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mask_yindao ver" v-else-if="mode == 'v' && this.prizeCountListT.length < 4"
                            ref="mask_yindao" :style="{ 'opacity': 0 }">
                            <div class="mask_yindao_wrap mask_yindao_wrap03">
                                <div class="mask_yindao_content">
                                    <img class="sao" src="../../../assets/card/sao.png" />
                                    <div class="sao_wrap">
                                        <img class="code" :src="acceptData.activity.qrcode_path" />
                                    </div>
                                    <img class="layer_prize" src="../../../assets/card/layer_prizes.png" />
                                </div>
                                <div class="layerLst_wrap">
                                    <div v-for="(item, index) in prizeCountListT" :key="index">
                                        <div class="victory_bg">
                                            <img class="victory_img" :src="item.victory_img" />
                                            <div class="victory_name">
                                                <p class="victory_text">{{ item.victory_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
let pageCurrent = "index";
let selectedActivity = "";
import { TweenMax } from 'gsap'
import {
    getPrizeList,
    getLatestList,
    getTopPrizeUserList,
    getPrizeNumList,
    getOverView,
    getPrizeNumListCard,
} from '@/api/littleGameOne.js'
export default {
    name: "moonBetting",
    data() {
        return {
        
            prizeCountListT: [],
            isscaleSuo: true,
            fullscreenLoading: false,
            uniq: "",
            acceptData: {},
            mode: 'h',
            state: 'going',
            countObj: {
                online: 0,
                join: 0,
                flow: 0,
                pass: 0
            },
            prizeList: [],
            oldUserList: [],
            userList: [],
            topUserList: [],
            prizeCountList: {},
            newList: [],
            count: 0,
            countRefresh: 0,
            currentIndex: 0,
            randomArr: [],
            scale: 1,
            wrapWidth: '',
            wrapHeight: '',
            bgx: '',
            reversed_width: '',
            reversed_height: '',
            scaleFen: 1,
            mleft: 0,
            mRight: 0,
            screen_part_type: 2,
            userLeft: 0,
            screenRadios: 1,
            fenshu: 'h',
            mt: 0,
            screenRadioY:1,
        }
    },
    mounted() {

        this.screen_type = localStorage.getItem('screen_type')
        let w = 0
        let h = 0
        if (this.screen_type == 9) {
            w = 1920
            h = 560
        } else if (this.screen_type == 8) {
            w = 1920
            h = 360
        } else if (this.screen_type == 7) {
            w = 1920
            h = 640
        } else if (this.screen_type == 6) {
            w = 1920
            h = 1080
        } else if (this.screen_type == 5) {
            w = 1600
            h = 1200
        } else if (this.screen_type == 4) {
            w = 1400
            h = 1400
        } else if (this.screen_type == 3) {
            w = 1000
            h = 1500
        } else if (this.screen_type == 2) {
            w = 1000
            h = 2000
        } else if (this.screen_type == 1) {//自定义
            w = localStorage.getItem('screen_width')
            h = localStorage.getItem('screen_length')
        } else if (this.screen_type == 0) {//默认自适应
            w = 1920
            h = 1080
            this.screen_part_type = 0

        }

        //横屏
        if (16 / 9 < w / h) {
            this.fenshu = 'h'
            this.wrapHeight = '100vh'
            this.wrapWidth = 16 / 9 * h + 'px'
            if (this.screen_type == 0) {//默认自适应
                this.screenRadio = 1
                this.mleft = 0
            } else {//3分|| 2分屏
                this.screenRadio = 16 / 9 / (w / h)
                this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
            }

        } else {
            //竖屏
            this.fenshu = 'v'
            this.wrapWidth = '100%'
            this.screenRadio = 1
            this.screenRadios = 9 / 16 * (w / h)
            console.log(this.screenRadios)
        }
        this.state = localStorage.getItem('state')
        let that = this
        setInterval(() => {
            that.open();
            setTimeout(() => {
                that.close()
            }, 5000)
        }, 10000)

        this.coll_id = this.$route.params.id;
        this.fullscreenLoading = true;
        this.resizeSetting();
    },
    methods: {
        open() {
            TweenMax.to(this.$refs.mask_yindao, 1, {
                opacity: 1,
            });
        },
        close() {
            TweenMax.to(this.$refs.mask_yindao, 1, {
                opacity: 0,
            });
        },
        getParentData(data,screenRadios) {
            this.screenRadioY = screenRadios
            if (
                ((pageCurrent && pageCurrent != data.current_select.current) ||
                    (selectedActivity && selectedActivity != data.current_select.selectValue))
            ) {
                if (this.countRefresh == 1) {
                    this.fullscreenLoading = true;
                    window.location.reload();
                    return;
                }
                this.countRefresh++
            }
            selectedActivity = data.current_select.selectValue;
            pageCurrent = data.current_select.current;

            this.uniq = data.activity.uniq;
            this.acceptData = { ...data };
            this.coll_id = this.$route.params.id;

            this.screen_part_type = this.acceptData.screen_part_type
            let w = 0
            let h = 0
            if (this.acceptData.screen_type == 9) {
                w = 1920
                h = 560
            } else if (this.acceptData.screen_type == 8) {
                w = 1920
                h = 360
            } else if (this.acceptData.screen_type == 7) {
                w = 1920
                h = 640
            } else if (this.acceptData.screen_type == 6) {
                w = 1920
                h = 1080
            } else if (this.acceptData.screen_type == 5) {
                w = 1600
                h = 1200
            } else if (this.acceptData.screen_type == 4) {
                w = 1400
                h = 1400
            } else if (this.acceptData.screen_type == 3) {
                w = 1000
                h = 1500
            } else if (this.acceptData.screen_type == 2) {
                w = 1000
                h = 2000
            } else if (this.acceptData.screen_type == 1) {//自定义
                w = this.acceptData.screen_width
                h = this.acceptData.screen_length
            } else if (this.acceptData.screen_type == 0) {//默认自适应
                w = 1920
                h = 1080
                this.screen_part_type = 0

            }

            //横屏
            if (16 / 9 < w / h) {
                this.fenshu = 'h'
                this.wrapHeight = '100vh'
                this.wrapWidth = 16 / 9 * h + 'px'
                if (this.acceptData.screen_type == 0) {//默认自适应
                    this.screenRadio = 1
                    this.mleft = 0
                } else {//3分|| 2分屏
                    this.screenRadio = 16 / 9 / (w / h)
                    this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
                }

            } else {
                //竖屏
                this.fenshu = 'v'
                this.wrapWidth = '100%'
                this.screenRadio = 1
                this.screenRadios = 9 / 16 * (w / h)
                if (this.$refs.activityWrap) {
                   this.$refs.activityWrap.style.transform = "scaleY(" + screenRadios + ")";
                }

                if (this.acceptData.screen_part_type == 3) {//2分屏幕
                    const _h = document.body.clientHeight;
                    this.mt = (_h - (_h * this.screenRadios)) / this.screenRadios + 'px'
                } else if (this.acceptData.screen_part_type == 2) {//3分屏幕
                    if (this.$refs.activityWrap) {
                        //this.$refs.activityWrap.style.top = h*(1 - this.screenRadios)/2 +'px';
                    }

                }
            }

            this.getPrizeList();
            this.getUserList();
            this.getPrizeListCount();
            this.getPrizeListCountT();
            this.getTopUserList();
            this.getOverViewNum(this.acceptData.activity.uniq);

            //表示活动未开始
            const current = new Date().getTime();
            if (data.activity) {
                if (current < this.acceptData.activity.begin_time * 1000) {
                  //  this.state = 'unstart';
                    this.count = 0;
                } else if (current > this.acceptData.activity.end_time * 1000 || this.acceptData.activity?.is_termi == 2) {
                   // this.state = 'finish';
                    this.count = 0;
                } else {
                    selectedActivity = data.current_select.selectValue;
                    this.current = data.current_select.current;
                    this.uniq = data.current_select.selectValue;
                    this.defaultcurrent = data.current_select.current;
                    this.activeDetail = data;
                   // this.state = 'going';
                    this.isscaleSuo = data.activity.resolution
                    if (this.count == 0) {
                        this.$nextTick(() => {
                            this.resizeSetting();
                            window.addEventListener("resize", () => {
                                this.resizeSetting();
                            });
                        })
                    }
                    this.count++;
                }
            } else {
                selectedActivity = data.current_select.selectValue;
                this.current = data.current_select.current;
                this.uniq = data.current_select.selectValue;
                this.defaultcurrent = data.current_select.current;
                this.activeDetail = data;
               // this.state = 'going';
            }

            this.fullscreenLoading = false;
        },

        getPrizeListCountT() {
            getPrizeNumListCard({ colle_uniq: this.coll_id, uniq: this.uniq, limit: 5 }).then((res) => {
                for (let i in res.data) {
                    if (res.data[i].victory_level == 1) {
                        res.data[i].victory_level_name = '一等奖'
                    }
                    if (res.data[i].victory_level == 2) {
                        res.data[i].victory_level_name = '二等奖'
                    }
                    if (res.data[i].victory_level == 3) {
                        res.data[i].victory_level_name = '三等奖'
                    }
                    if (res.data[i].victory_level == 4) {
                        res.data[i].victory_level_name = '四等奖'
                    }
                    if (res.data[i].victory_level == 5) {
                        res.data[i].victory_level_name = '五等奖'
                    }
                    if (res.data[i].victory_level == 6) {
                        res.data[i].victory_level_name = '六等奖'
                    }
                    if (res.data[i].victory_level == 7) {
                        res.data[i].victory_level_name = '七等奖'
                    }
                    if (res.data[i].victory_level == 8) {
                        res.data[i].victory_level_name = '八等奖'
                    }
                    if (res.data[i].victory_level == 9) {
                        res.data[i].victory_level_name = '九等奖'
                    }
                    if (res.data[i].victory_level == 10) {
                        res.data[i].victory_level_name = '十等奖'
                    }

                }
                this.prizeCountListT = res.data


            })
        },

        getPrizeList() {
            getPrizeList({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                if (res.status == 200) {
                    this.prizeList = res.data;
                    this.prizeList.map((item) => {
                        return {
                            ...item,
                            victory_img: item.victory_img ? item.victory_img : 'https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b'
                        }
                    })
                }
            })
        },
        getOverViewNum(uniq) {
            getOverView({
                uniq: uniq,
                colle_uniq: this.coll_id,
            }).then((res) => {
                if (res.status == 200) {
                    this.countObj = res.data;
                }

            })
        },
        getUserList() {
            getLatestList({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                let userlist = typeof res.data == 'object' ? Object.values(res.data) : res.data;
                // let max = userlist ? userlist.length : 0;
                if (userlist.length != this.oldUserList.length) {
                    const chabie = userlist.length - this.oldUserList.length;git 
                    this.currentIndex = chabie - 1;
                    if (this.currentIndex != -1) {
                        this.userList.push(userlist[this.currentIndex]);
                        TweenMax.to(this.$refs.box, 1, {
                            scrollTop: this.$refs.box.scrollHeight
                        });
                        if (userlist[this.currentIndex].win_type > 0) {
                            this.getUserPop(userlist[this.currentIndex]);
                            TweenMax.to(this.$refs.mask, 1, {
                                opacity: 1,
                            });
                        }
                        this.currentIndex--;
                    }

                    this.oldUserList = this.userList;
                }

            })
        },
        getUserPop(data) {
            if (this.randomArr.length == 0) {
                this.randomArr = this.getUniqueRand(0, 1, 2);
            }
            data.index = this.randomArr[0];
            this.randomArr.push(this.randomArr[0]);
            this.randomArr.splice(0, 1);
            this.newList.push(data);
            setTimeout(() => {
                this.newList.splice(0, 1);
                if (this.newList.length == 0) {
                    TweenMax.to(this.$refs.mask, 1, {
                        opacity: 0,
                    });
                }
            }, 3600);
        },
        getTopUserList() {
            getTopPrizeUserList({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                this.topUserList = res.data;
                const length = 10 - res.data.length;
                if (res.data.length < 10) {
                    for (let i = 0; i < length; i++) {
                        this.topUserList.push({
                            act_id: 0,
                            avatar: "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
                            nickname: "- -",
                            victory_name: "- -",
                            win_name: "- -",
                            win_type: 0,
                        });
                    }
                }
            })
        },
        getPrizeListCount() {
            getPrizeNumList({ colle_uniq: this.coll_id, uniq: this.uniq }).then((res) => {
                for (let item in res.data) {
                    this.prizeCountList[item] = [...res.data[item]];

                }
            })
        },
        getRandNum(minNum, maxNum, decimalNum) {
            let max = 0, min = 0;
            minNum <= maxNum ? (min = minNum, max = maxNum) : (min = maxNum, max = minNum);
            switch (arguments.length) {
                case 1:
                    return Math.floor(Math.random() * (max + 1));
                case 2:
                    return Math.floor(Math.random() * (max - min + 1) + min);
                case 3:
                    return (Math.random() * (max - min) + min).toFixed(decimalNum);
                default:
                    return Math.random();
            }
        },
        getUniqueRand(min, max, num) {
            const randArr = [];
            for (let i = 0; i < num; i++) {
                const rand = this.getRandNum(min, max);
                if (randArr.indexOf(rand) == -1) {
                    randArr.push(rand);
                } else {
                    i--;
                }
            }
            return randArr
        },
        resizeSetting() {
            const _w = document.body.clientWidth;
            const _h = document.body.clientHeight;
            this.mode = _w >= _h ? "h" : "v";
            if (this.mode == 'h') {
                const rate = _w > 1440 ? 1920 / 1080 : 1440 / 1080;
                this.isOverTop = rate < _w / _h;
                if (this.$refs.container) {
                    const fixWidth = _w > 1440 ? 1920 : 1440;
                    console.log(this.isOverTop)
                    if (this.isOverTop) {
                        this.$refs.container.style.transform = "scale(" + _h / 1080 + ")";
                        this.$refs.container.style.marginTop = -((1080 - _h) / 2 - 1) + "px";
                        this.$refs.container.style.marginLeft = -(fixWidth - _w) / 2 + "px";
                    } else {
                        const currH = _h / (_w / fixWidth);

                        const _style = this.$refs.container.getAttribute("style");
                        const filter_style = _style ? _style.split(";").filter((item) => {
                            return !(item.indexOf("min-width") > -1)
                        }) : "";
                        const _new_style = filter_style.length > 0 ? filter_style.join(";") : "";
                        this.$refs.container.style = _new_style;
                        this.$refs.container.style.transform = "scale(" + _w / fixWidth + ")";
                        this.$refs.container.style.marginTop = ((_h - currH) / 2) + "px";
                        this.$refs.container.style.marginLeft = -((_w / (_w / fixWidth)) - _w) / 2 + "px";
                        this.$refs.container.style.minHeight = currH + "px";
                        this.$refs.container.style.maxHeight = currH + "px";
                        if (this.$refs.content) {
                            this.$refs.content.style.height = currH - 484 + "px";
                        }
                        this.scale = _w / fixWidth;
                    }
                }

            } else {
                if (this.$refs.container) {
                    const currH = (_h / (_w / 1380));
                    const currW = (_w / (_w / 1380));
                    this.$refs.container.style.transform = "scale(" + _w / 1380 + ")";
                    this.$refs.container.style.marginTop = -(currH - _h) / 2 + "px";
                    this.$refs.container.style.marginLeft = -(currW - _w) / 2 + "px";
                    this.$refs.container.style.minHeight = currH + "px";
                    this.$refs.container.style.maxHeight = currH + "px";
                    if (this.$refs.content) {
                        if (this.acceptData.activity.left_banner_img) {
                            this.$refs.content.style.height = currH - 1100 + "px";
                        } else {
                            this.$refs.content.style.height = currH - 920 + "px";
                        }

                    }
                    this.scale = _w / 1380;
                }
            }
        }
    }

}
</script>
<style scoped lang="less">
@import url('./style.less');

.sao_wrap_img .code {
    width: 560px;
    height: 560px;
}

.mCenter {
    margin: 0 auto;
}

.pd20 {
    padding: 0 40px;
}

.dingwei {
    position: absolute;
}

.weizhi {
    position: absolute;
}

.mt240 {
    margin-top: -240px;
}

.maxWdith {
    width: 105px;
}

.resopnse {
    position: absolute;
    top: 0
}</style>
    