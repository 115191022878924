<template>
  <div style="height: 100vh">
    <div class="activity-counter" ref="counter" v-if="(activeDetail.reside_show == 1 || activeDetail.flow_show == 1) &&
      activeDetail.current_select.current == 'index'
      ">
      <ul>
        <li v-if="activeDetail.reside_show == 1">
          <strong class="count-font yellow">{{ countObj.flow || 0 }}</strong>
          <span>驻留人数</span>
        </li>
        <li v-if="activeDetail.flow_show == 1">
          <strong class="count-font purple">{{ countObj.pass || 0 }}</strong>
          <span>客流人次</span>
        </li>
      </ul>
    </div>
    <div class="activity-back" v-if="activeDetail && activeDetail.current_select.current == 'index'"
      :style="{ 'background-image': 'url(' + activeDetail.index_img + ')' }"></div>
    <component v-else :is="currentCompnent" ref="child" />
  </div>
</template>

<script>
import { tenActiveDetailCollec, getOverView } from "@/api/littleGameOne.js";
import moduleRedPackage from "./redPackage/index.vue";
import moduleTenChallenge from "./tenChallenge/index.vue";
import moduleQuestions from "./questions/index.vue";
import moduleMoonBetting from "./moonBetting/index.vue";
import moduleCard from "./card/index.vue";
import moduledao from "./feidao/index.vue";
import moduleCar from "./car/index.vue";
import moduleAnimal from "./aminal/index.vue";
import slamDunk from "../slamDunk/index.vue";
import modulePing from "./ping/index.vue";
import moduleCardguess from "./cardguess/index.vue";
import modulelele from "./lele/index.vue";
import modulehongbao from "./hongbao/index.vue";
import moduleLiu from "./liuyan/index.vue";

export default {
  name: "tenChallenge",
  data() {
    return {
      wrapWidth: '',
      wrapHeight: '',
      reversed_width: '',
      reversed_height: '',
      userLeft: 0,
      screenRadios: 1,
      screenRadio:1,
      fenshu: 'h',
      state: "",
      coll_id: "",
      uniq: "",
      mleft:0,
      mt:0,
      componentObj: {
        1: "moduleTenChallenge",
        2: "moduleRedPackage",
        3: "moduleQuestions",
        4: "moduleMoonBetting",
        5: "slamDunk",
        6: "slamDunk",
        7: "moduleCard",
        8: "moduledao",
        9: "moduleCar",
        10: "moduleAnimal",
        11: 'modulePing',
        12: 'moduleCardguess',
        13:'modulelele',
        14:'modulehongbao',
        15:'moduleLiu'
      },
      currentCompnent: "",
      activeDetail: {
        current_select: {},
      },
      countObj: {},
    };
  },
  components: {
    moduleRedPackage,
    moduleTenChallenge,
    moduleQuestions,
    moduleMoonBetting,
    slamDunk,
    moduleCard,
    moduledao,
    moduleCar,
    moduleAnimal,
    modulePing,
    moduleCardguess,
    modulelele,
    modulehongbao,
    moduleLiu

  },
  mounted() {
    this.coll_id = this.$route.params.id;
    this.getDetail();
    setInterval(async () => {
      await this.getDetail();
    }, 2000);

  },
  methods: {
    getDetail() {
      tenActiveDetailCollec({
        colle_uniq: this.coll_id,
      }).then((res) => {
        console.log(res)
        if (res.status == 200) {
          if (res.data.refresh === 1) {
            return window.location.reload();
          }
          this.activeDetail = res.data;
          this.uniq = res.data;
          localStorage.setItem('screen_type', this.activeDetail.screen_type)
          localStorage.setItem('colle_uniq', this.activeDetail.colle_uniq)
          localStorage.setItem('selectValue', this.activeDetail.current_select.selectValue)

          if(this.activeDetail.activity.add_info && this.activeDetail.activity.add_info.dan_layout){
            localStorage.setItem('dan_layout', this.activeDetail.activity.add_info.dan_layout)
          }
          if(this.activeDetail.activity.add_info && this.activeDetail.activity.add_info.dan_line){
            localStorage.setItem('dan_line', this.activeDetail.activity.add_info.dan_line)
          }
          if(this.activeDetail.activity.add_info && this.activeDetail.activity.add_info.dan_size){
            localStorage.setItem('dan_size', this.activeDetail.activity.add_info.dan_size)
          }

          let w = 0
          let h = 0
          if (this.activeDetail.screen_type == 9) {
            w = 1920
            h = 560
          } else if (this.activeDetail.screen_type == 8) {
            w = 1920
            h = 360
          } else if (this.activeDetail.screen_type == 7) {
            w = 1920
            h = 640
          } else if (this.activeDetail.screen_type == 6) {
            w = 1920
            h = 1080
          } else if (this.activeDetail.screen_type == 5) {
            w = 1600
            h = 1200
          } else if (this.activeDetail.screen_type == 4) {
            w = 1400
            h = 1400
          } else if (this.activeDetail.screen_type == 3) {
            w = 1000
            h = 1500
          } else if (this.activeDetail.screen_type == 2) {
            w = 1000
            h = 2000
          } else if (this.activeDetail.screen_type == 1) {//自定义
            w = this.activeDetail.screen_width
            h = this.activeDetail.screen_length
          } else if (this.activeDetail.screen_type == 0) {//默认自适应
            w = 1920
            h = 1080
            this.screen_part_type = 0

          }

          //横屏
          if (16 / 9 < w / h) {
            this.fenshu = 'h'
            this.wrapHeight = '100vh'
            this.wrapWidth = 16 / 9 * h + 'px'
            localStorage.setItem('wrapWidth', this.wrapWidth)
            localStorage.setItem('wrapHeight', this.wrapHeight)
            localStorage.setItem('fenshu', this.fenshu)
            if (this.activeDetail.screen_type == 0) {//默认自适应
              this.screenRadio = 1
              this.mleft = 0
              localStorage.setItem('mleft', this.mleft)
              localStorage.setItem('screenRadio', this.screenRadio)
            } else {
              this.screenRadio = 16 / 9 / (w / h)
              localStorage.setItem('screenRadio', this.screenRadio)
              this.mleft = -(250 * (1 - this.screenRadio) * 0.5)
              localStorage.setItem('mleft', this.mleft)
             
            }

          } else {
            //竖屏
            this.fenshu = 'v'
            this.screenRadio = 1
            this.screenRadios = 9 / 16 * (w / h)
            console.log(this.screenRadios)
            localStorage.setItem('screenRadios', this.screenRadios)
            localStorage.setItem('fenshu', this.fenshu)
            if(this.activeDetail.screen_part_type==3){//2分屏幕
                    const _h = document.body.clientHeight;
                    this.mt = (_h - (_h*this.screenRadios))/this.screenRadios +'px'
                   
                }

          }


          if (this.activeDetail.screen_type == 1) {
            localStorage.setItem('screen_width', this.activeDetail.screen_width)
            localStorage.setItem('screen_length', this.activeDetail.screen_length)
          }

          //表示活动未开始
          const current = new Date().getTime();
          if (this.activeDetail.activity) {
            if (current < this.activeDetail.activity.begin_time * 1000) {
              this.state = 'unstart';
              localStorage.setItem('state', this.state)
            } else if (current > this.activeDetail.activity.end_time * 1000 || this.activeDetail.activity?.is_termi == 2) {
              this.state = 'finish';
              localStorage.setItem('state', this.state)
            } else {
              this.state = 'going';
              localStorage.setItem('state', this.state)

            }
          } else {
            this.state = 'going';
            localStorage.setItem('state', this.state)
          }

          if (res.data.current_select.current == "activity") {
            //active_type 1 十秒挑战 2 摇红包 3答题活动
            this.currentCompnent =
              this.componentObj[res.data.activity.active_type];
            if (this.$refs.child && this.$refs.child.getParentData) {
              this.$refs.child.getParentData(this.activeDetail,this.screenRadios);
            }
          } else {
            getOverView({
              colle_uniq: this.coll_id,
            }).then((res) => {
              if (res.status == 200) {
                console.log(res.data)
                this.countObj = res.data;
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less">
.activity-back {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;

  .activity-back-img {
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

.green {
  color: #c7ff00;
}

.orange {
  color: #ff8900;
}

.yellow {
  color: #ffd700;
}

.purple {
  color: #bc7bff;
}

.yellow1 {
  color: #fffd00;
}

.activity-counter {
  background-color: rgba(0, 0, 0, 0.49);
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  z-index: 15;

  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
    color: #fff;
    font-size: 14px;
    padding: 0 12px;
    text-align: center;
    width: 85px;

    .count-font {
      display: block;
      text-align: center;
      font-size: 20px;
    }
  }
}
</style>
